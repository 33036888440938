import React from "react";
import NavButtonBar from "./NavButtonBar";
import Typography from "@mui/material/Typography";
// import {IntxBuilder} from "../TempEngine/IntxBuilder";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";
import {
  Interchanges,
  IntxIconMapper,
  SignalizedIntersections,
  UnsignalizedIntersections,
} from "../Util/IntersectionHelper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { set } from "date-fns";

const STEP_INDEX = 3;
const EXCLUDE_REASONS = [
  {
    id: 1,
    label: "Not feasible for roadway facility type",
  },
  {
    id: 2,
    label: "Insufficient intersection spacing",
  },
  {
    id: 3,
    label: "Financial constraints identified",
  },
  {
    id: 4,
    label: "Right-of-way restrictions identified",
  },
  {
    id: 5,
    label: "Unable to accommodate traffic patterns",
  },
  {
    id: 6,
    label: "Unable to accommodate magnitude of traffic volumes",
  },
  {
    id: 7,
    label: "Unable to accommodate heavy vehicles",
  },
  {
    id: 8,
    label: "Unable to accommodate alternative transportation modes",
  },
];

const ExcludeAllDialog = ({ applyExclusionReason, handleClose, open }) => {
  const [excludeAllReason, setExcludeAllReason] = React.useState("");
  const handleExcludeAllReasonChange = (event) => {
    setExcludeAllReason(event.target.value);
  };
  const handleApplyReason = React.useCallback(() => {
    applyExclusionReason(excludeAllReason);
    handleClose();
  }, [applyExclusionReason, handleClose, excludeAllReason]);

  return (
    <Dialog open={open} maxWidth={"sm"} fullWidth>
      <DialogTitle>Fill Exclusion Justification</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ marginTop: 1, width: "100%" }}>
            <InputLabel id="select-fill-justification-label">
              Select Reason
            </InputLabel>
            <Select
              id="select-fill-justification"
              // sx={{fontSize: "10pt"}}
              value={excludeAllReason}
              onChange={handleExcludeAllReasonChange}
              labelId="select-fill-justification-label"
              label="Select Reason"
            >
              {/*<MenuItem disabled value=""><em>Select Reason</em></MenuItem>*/}
              {EXCLUDE_REASONS.map((excludeOpt, index) => (
                <MenuItem key={"exclude-reason-" + index} value={excludeOpt.id}>
                  {excludeOpt.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {excludeAllReason !== "" && (
            <Button
              size="small"
              color="inherit"
              variant="outlined"
              aria-label="reset"
              sx={{ marginLeft: "10px" }}
              endIcon={<FontAwesomeIcon icon="fa-solid fa-reply-all" />}
              onClick={(event) => {
                setExcludeAllReason("");
              }}
            >
              Reset
            </Button>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleApplyReason}>Apply</Button>
      </DialogActions>
    </Dialog>
  );
};

const selectColWidth = "400px";
const subHeaderStyle = { textDecoration: "underline", fontWeight: "bold" };
export default function ExclusionJustificationPanel(props) {
  const {
    assignExclusionsToProject,
    launchExportDialog,
    projectAlternatives,
    projectExclusions,
    updateActiveStep,
  } = props;

  React.useEffect(() => {
    updateActiveStep(STEP_INDEX);
  });

  const initializeExcludedDesignOptionsList = () => {
    const newExcludedList = {
      [IntxBuilder.SIGNALIZED]: {},
      [IntxBuilder.UNSIGNALIZED]: {},
      [IntxBuilder.CAT_INTERCHANGE]: {},
    };

    const projectIncludedTypesMap = projectAlternatives.map(
      (option) => option.type
    );

    for (let ii = 0; ii < SignalizedIntersections.length; ii++) {
      if (
        projectIncludedTypesMap.indexOf(SignalizedIntersections[ii].type) < 0
      ) {
        let exclusionReason = "";
        if (
          projectExclusions[IntxBuilder.SIGNALIZED] &&
          projectExclusions[IntxBuilder.SIGNALIZED][
            SignalizedIntersections[ii].type
          ]
        ) {
          exclusionReason =
            projectExclusions[IntxBuilder.SIGNALIZED][
              SignalizedIntersections[ii].type
            ].reason || "";
        }
        newExcludedList[IntxBuilder.SIGNALIZED][
          SignalizedIntersections[ii].type
        ] = {
          label: SignalizedIntersections[ii].title,
          reason: exclusionReason,
        };
      }
    }

    for (let ii = 0; ii < UnsignalizedIntersections.length; ii++) {
      if (
        projectIncludedTypesMap.indexOf(UnsignalizedIntersections[ii].type) < 0
      ) {
        let exclusionReason = "";
        if (
          projectExclusions[IntxBuilder.UNSIGNALIZED] &&
          projectExclusions[IntxBuilder.UNSIGNALIZED][
            UnsignalizedIntersections[ii].type
          ]
        ) {
          exclusionReason =
            projectExclusions[IntxBuilder.UNSIGNALIZED][
              UnsignalizedIntersections[ii].type
            ].reason || "";
        }
        newExcludedList[IntxBuilder.UNSIGNALIZED][
          UnsignalizedIntersections[ii].type
        ] = {
          label: UnsignalizedIntersections[ii].title,
          reason: exclusionReason,
        };
      }
    }

    for (let ii = 0; ii < Interchanges.length; ii++) {
      if (projectIncludedTypesMap.indexOf(Interchanges[ii].type) < 0) {
        let exclusionReason = "";
        if (
          projectExclusions[IntxBuilder.CAT_INTERCHANGE] &&
          projectExclusions[IntxBuilder.CAT_INTERCHANGE][Interchanges[ii].type]
        ) {
          exclusionReason =
            projectExclusions[IntxBuilder.CAT_INTERCHANGE][
              Interchanges[ii].type
            ].reason || "";
        }
        newExcludedList[IntxBuilder.CAT_INTERCHANGE][Interchanges[ii].type] = {
          label: Interchanges[ii].title,
          reason: exclusionReason,
        };
      }
    }

    return newExcludedList;
  };

  const initializeExclusionCounts = () => {
    const counts = {
      [IntxBuilder.SIGNALIZED]: 0,
      [IntxBuilder.UNSIGNALIZED]: 0,
      [IntxBuilder.CAT_INTERCHANGE]: 0,
    };

    const projectIncludedTypesMap = projectAlternatives.map(
      (option) => option.type
    );

    for (let ii = 0; ii < SignalizedIntersections.length; ii++) {
      if (
        projectIncludedTypesMap.indexOf(SignalizedIntersections[ii].type) < 0
      ) {
        counts[IntxBuilder.SIGNALIZED]++;
      }
    }

    for (let ii = 0; ii < UnsignalizedIntersections.length; ii++) {
      if (
        projectIncludedTypesMap.indexOf(UnsignalizedIntersections[ii].type) < 0
      ) {
        counts[IntxBuilder.UNSIGNALIZED]++;
      }
    }

    for (let ii = 0; ii < Interchanges.length; ii++) {
      if (projectIncludedTypesMap.indexOf(Interchanges[ii].type) < 0) {
        counts[IntxBuilder.CAT_INTERCHANGE]++;
      }
    }

    return counts;
  };

  const [excludedDesignOptionsList, setExcludedDesignOptionsList] =
    React.useState(() =>
      projectAlternatives.length > 0 && projectExclusions
        ? initializeExcludedDesignOptionsList()
        : {
            [IntxBuilder.SIGNALIZED]: {},
            [IntxBuilder.UNSIGNALIZED]: {},
            [IntxBuilder.CAT_INTERCHANGE]: {},
          }
    );
  const [exclusionCounts] = React.useState(() =>
    projectAlternatives.length > 0
      ? initializeExclusionCounts()
      : {
          [IntxBuilder.SIGNALIZED]: 0,
          [IntxBuilder.UNSIGNALIZED]: 0,
          [IntxBuilder.CAT_INTERCHANGE]: 0,
        }
  );

  const handleReasonChange = (category, type, newReason) => {
    setExcludedDesignOptionsList((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [type]: {
          ...prevState[category][type],
          reason: newReason,
        },
      },
    }));

    assignExclusionsToProject(excludedDesignOptionsList);
    console.log("handleReasonChange, assign to project");
  };

  const navigate = useNavigate();

  const saveExclusionsToProject = React.useCallback(() => {
    assignExclusionsToProject(excludedDesignOptionsList);
    let count = 0;

    for (const key of Object.keys(
      excludedDesignOptionsList[IntxBuilder.SIGNALIZED]
    )) {
      if (
        excludedDesignOptionsList[IntxBuilder.SIGNALIZED].hasOwnProperty(key)
      ) {
        if (
          excludedDesignOptionsList[IntxBuilder.SIGNALIZED][key].reason === ""
        ) {
          count++;
        }
      }
    }

    for (const key of Object.keys(
      excludedDesignOptionsList[IntxBuilder.UNSIGNALIZED]
    )) {
      if (
        excludedDesignOptionsList[IntxBuilder.UNSIGNALIZED].hasOwnProperty(key)
      ) {
        if (
          excludedDesignOptionsList[IntxBuilder.UNSIGNALIZED][key].reason === ""
        ) {
          count++;
        }
      }
    }

    for (const key of Object.keys(
      excludedDesignOptionsList[IntxBuilder.CAT_INTERCHANGE]
    )) {
      if (
        excludedDesignOptionsList[IntxBuilder.CAT_INTERCHANGE].hasOwnProperty(
          key
        )
      ) {
        if (
          excludedDesignOptionsList[IntxBuilder.CAT_INTERCHANGE][key].reason ===
          ""
        ) {
          count++;
        }
      }
    }
    // console.log(count);
    if (count > 0) {
      setShowSkipExclusionDialog(true);
    }
  }, [assignExclusionsToProject, excludedDesignOptionsList]);

  const handleInternalLaunchExportDialog = React.useCallback(() => {
    saveExclusionsToProject();
    launchExportDialog();
  }, [launchExportDialog, saveExclusionsToProject]);

  const [showExcludeAllSignalizedDialog, setShowExcludeAllSignalizedDialog] =
    React.useState(false);
  const handleShowExcludeAllSignalizedDialog = () => {
    setShowExcludeAllSignalizedDialog(true);
  };
  const handleCloseExcludeAllSignalizedDialog = () => {
    setShowExcludeAllSignalizedDialog(false);
  };
  const applyReasonToSignalized = React.useCallback(
    (excludeAllReason) => {
      for (const key of Object.keys(
        excludedDesignOptionsList[IntxBuilder.SIGNALIZED]
      )) {
        if (
          excludedDesignOptionsList[IntxBuilder.SIGNALIZED].hasOwnProperty(key)
        ) {
          handleReasonChange(IntxBuilder.SIGNALIZED, key, excludeAllReason);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [excludedDesignOptionsList]
  );

  const [
    showExcludeAllUnsignalizedDialog,
    setShowExcludeAllUnsignalizedDialog,
  ] = React.useState(false);
  const handleShowExcludeAllUnsignalizedDialog = () => {
    setShowExcludeAllUnsignalizedDialog(true);
  };
  const handleCloseExcludeAllUnsignalizedDialog = () => {
    setShowExcludeAllUnsignalizedDialog(false);
  };
  const applyReasonToUnsignalized = React.useCallback(
    (excludeAllReason) => {
      for (const key of Object.keys(
        excludedDesignOptionsList[IntxBuilder.UNSIGNALIZED]
      )) {
        if (
          excludedDesignOptionsList[IntxBuilder.UNSIGNALIZED].hasOwnProperty(
            key
          )
        ) {
          handleReasonChange(IntxBuilder.UNSIGNALIZED, key, excludeAllReason);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [excludedDesignOptionsList]
  );

  const [showExcludeAllInterchangeDialog, setShowExcludeAllInterchangeDialog] =
    React.useState(false);
  const handleShowExcludeAllInterchangeDialog = () => {
    setShowExcludeAllInterchangeDialog(true);
  };
  const handleCloseExcludeAllInterchangeDialog = () => {
    setShowExcludeAllInterchangeDialog(false);
  };
  const applyReasonToInterchanges = React.useCallback(
    (excludeAllReason) => {
      for (const key of Object.keys(
        excludedDesignOptionsList[IntxBuilder.CAT_INTERCHANGE]
      )) {
        if (
          excludedDesignOptionsList[IntxBuilder.CAT_INTERCHANGE].hasOwnProperty(
            key
          )
        ) {
          handleReasonChange(
            IntxBuilder.CAT_INTERCHANGE,
            key,
            excludeAllReason
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [excludedDesignOptionsList]
  );

  const [showSkipExclusionDialog, setShowSkipExclusionDialog] =
    React.useState(false);
  // const handleShowSkipExclusionDialog = () => {setShowSkipExclusionDialog(true)}
  const handleCloseSkipExclusionDialog = () => {
    setShowSkipExclusionDialog(false);
  };
  const handleSkipToNext = React.useCallback(() => {
    navigate(`/analysis/step${STEP_INDEX + 2}`);
  }, [navigate]);

  //console.log("excludedDesignOptionsList", excludedDesignOptionsList);

  return (
    <div
      style={{
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "20px",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          height: "calc(100% - 50px)",
          overflow: "auto",
          paddingBottom: "20px",
        }}
      >
        <Grid container spacing={1} sx={{ height: "100%" }}>
          <Grid item xs={12} sx={{ height: "60px" }}>
            <Typography variant="h5">Exclusion Justification</Typography>
            <Typography variant="body2">
              <em>
                Provide justification for any design options not included in the
                analysis.
              </em>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ height: "10px" }}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ height: "calc(100% - 70px)", overflow: "auto" }}
          >
            <Grid container spacing={1}>
              {exclusionCounts[IntxBuilder.SIGNALIZED] > 0 && (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", paddingBottom: "10px" }}
                  >
                    <div
                      style={{
                        minWidth: "135px",
                        maxWidth: "317px",
                        flexGrow: 1,
                      }}
                    >
                      <Typography variant="body1" sx={subHeaderStyle}>
                        Signalized Intersections
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "block",
                        minWidth: selectColWidth,
                        maxWidth: selectColWidth,
                      }}
                    >
                      <div style={{ float: "right" }}>
                        <Tooltip
                          title={
                            <span style={{ fontSize: "11pt" }}>
                              Apply single reason to all{" "}
                              <b>
                                <u>signalized intersections</u>
                              </b>{" "}
                              exclusions.
                            </span>
                          }
                          placement="top"
                          arrow
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={handleShowExcludeAllSignalizedDialog}
                            endIcon={
                              <FontAwesomeIcon icon="fa-solid fa-list-dropdown" />
                            }
                          >
                            Fill Reason
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {Object.entries(
                        excludedDesignOptionsList[IntxBuilder.SIGNALIZED]
                      ).map(([key, value], index) => (
                        <React.Fragment key={key}>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                minWidth: "25px",
                                maxWidth: "25px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography align={"center"}>
                                {index + 1}
                              </Typography>
                            </div>
                            <div
                              style={{
                                minWidth: "30px",
                                maxWidth: "30px",
                                marginLeft: "10px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                alt="intersection icon"
                                src={IntxIconMapper[key]}
                                height={"25px"}
                              />
                            </div>
                            <div
                              style={{
                                minWidth: "50px",
                                maxWidth: "250px",
                                marginLeft: "10px",
                                flexGrow: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography>{value.label}</Typography>
                            </div>
                            <div
                              style={{
                                minWidth: selectColWidth,
                                maxWidth: selectColWidth,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Select
                                sx={{ fontSize: "10pt" }}
                                size={"small"}
                                value={value.reason}
                                onChange={(event) => {
                                  handleReasonChange(
                                    IntxBuilder.SIGNALIZED,
                                    key,
                                    event.target.value
                                  );
                                }}
                                fullWidth
                              >
                                <MenuItem disabled value="">
                                  <em>Select Reason</em>
                                </MenuItem>
                                {EXCLUDE_REASONS.map((excludeOpt, index) => (
                                  <MenuItem
                                    key={"exclude-reason-" + index}
                                    value={excludeOpt.id}
                                  >
                                    {excludeOpt.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              {exclusionCounts[IntxBuilder.UNSIGNALIZED] > 0 && (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", paddingBottom: "10px" }}
                  >
                    <div
                      style={{
                        minWidth: "135px",
                        maxWidth: "317px",
                        flexGrow: 1,
                      }}
                    >
                      <Typography variant="body1" sx={subHeaderStyle}>
                        Unsignalized Intersections
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "block",
                        minWidth: selectColWidth,
                        maxWidth: selectColWidth,
                      }}
                    >
                      <div style={{ float: "right" }}>
                        <Tooltip
                          title={
                            <span style={{ fontSize: "11pt" }}>
                              Apply single reason to all{" "}
                              <b>
                                <u>unsignalized intersections</u>
                              </b>{" "}
                              exclusions.
                            </span>
                          }
                          placement="top"
                          arrow
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={handleShowExcludeAllUnsignalizedDialog}
                            endIcon={
                              <FontAwesomeIcon icon="fa-solid fa-list-dropdown" />
                            }
                          >
                            Fill Reason
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {Object.entries(
                        excludedDesignOptionsList[IntxBuilder.UNSIGNALIZED]
                      ).map(([key, value], index) => (
                        <React.Fragment key={key}>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                minWidth: "25px",
                                maxWidth: "25px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography align={"center"}>
                                {index + 1}
                              </Typography>
                            </div>
                            <div
                              style={{
                                minWidth: "30px",
                                maxWidth: "30px",
                                marginLeft: "10px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                alt="intersection icon"
                                src={IntxIconMapper[key]}
                                height={"25px"}
                              />
                            </div>
                            <div
                              style={{
                                minWidth: "50px",
                                maxWidth: "250px",
                                marginLeft: "10px",
                                flexGrow: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography>{value.label}</Typography>
                            </div>
                            <div
                              style={{
                                minWidth: selectColWidth,
                                maxWidth: selectColWidth,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Select
                                sx={{ fontSize: "10pt" }}
                                size={"small"}
                                value={value.reason}
                                onChange={(event) => {
                                  handleReasonChange(
                                    IntxBuilder.UNSIGNALIZED,
                                    key,
                                    event.target.value
                                  );
                                }}
                                fullWidth
                              >
                                <MenuItem disabled value="">
                                  <em>Select Reason</em>
                                </MenuItem>
                                {EXCLUDE_REASONS.map((excludeOpt, index) => (
                                  <MenuItem
                                    key={"exclude-reason-" + index}
                                    value={excludeOpt.id}
                                  >
                                    {excludeOpt.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              {exclusionCounts[IntxBuilder.CAT_INTERCHANGE] > 0 && (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", paddingBottom: "10px" }}
                  >
                    <div
                      style={{
                        minWidth: "135px",
                        maxWidth: "317px",
                        flexGrow: 1,
                      }}
                    >
                      <Typography variant="body1" sx={subHeaderStyle}>
                        Interchanges
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "block",
                        minWidth: selectColWidth,
                        maxWidth: selectColWidth,
                      }}
                    >
                      <div style={{ float: "right" }}>
                        <Tooltip
                          title={
                            <span style={{ fontSize: "11pt" }}>
                              Apply single reason to all{" "}
                              <b>
                                <u>interchange</u>
                              </b>{" "}
                              exclusions.
                            </span>
                          }
                          placement="top"
                          arrow
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={handleShowExcludeAllInterchangeDialog}
                            endIcon={
                              <FontAwesomeIcon icon="fa-solid fa-list-dropdown" />
                            }
                          >
                            Fill Reason
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {Object.entries(
                        excludedDesignOptionsList[IntxBuilder.CAT_INTERCHANGE]
                      ).map(([key, value], index) => (
                        <React.Fragment key={key}>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                minWidth: "25px",
                                maxWidth: "25px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography align={"center"}>
                                {index + 1}
                              </Typography>
                            </div>
                            <div
                              style={{
                                minWidth: "30px",
                                maxWidth: "30px",
                                marginLeft: "10px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                alt="intersection icon"
                                src={IntxIconMapper[key]}
                                height={"25px"}
                              />
                            </div>
                            <div
                              style={{
                                minWidth: "50px",
                                maxWidth: "250px",
                                marginLeft: "10px",
                                flexGrow: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography>{value.label}</Typography>
                            </div>
                            <div
                              style={{
                                minWidth: selectColWidth,
                                maxWidth: selectColWidth,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Select
                                sx={{ fontSize: "10pt" }}
                                size={"small"}
                                value={value.reason}
                                onChange={(event) => {
                                  handleReasonChange(
                                    IntxBuilder.CAT_INTERCHANGE,
                                    key,
                                    event.target.value
                                  );
                                }}
                                fullWidth
                              >
                                <MenuItem disabled value="">
                                  <em>Select Reason</em>
                                </MenuItem>
                                {EXCLUDE_REASONS.map((excludeOpt, index) => (
                                  <MenuItem
                                    key={"exclude-reason-" + index}
                                    value={excludeOpt.id}
                                  >
                                    {excludeOpt.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <NavButtonBar
        stepIndex={STEP_INDEX}
        launchExportDialog={handleInternalLaunchExportDialog}
        unlinkNext={true}
        handleNext={saveExclusionsToProject}
        handleBack={saveExclusionsToProject}
      />
      <ExcludeAllDialog
        applyExclusionReason={applyReasonToSignalized}
        handleClose={handleCloseExcludeAllSignalizedDialog}
        open={showExcludeAllSignalizedDialog}
      />
      <ExcludeAllDialog
        applyExclusionReason={applyReasonToUnsignalized}
        handleClose={handleCloseExcludeAllUnsignalizedDialog}
        open={showExcludeAllUnsignalizedDialog}
      />
      <ExcludeAllDialog
        applyExclusionReason={applyReasonToInterchanges}
        handleClose={handleCloseExcludeAllInterchangeDialog}
        open={showExcludeAllInterchangeDialog}
      />
      <Dialog open={showSkipExclusionDialog} maxWidth={"sm"} fullWidth>
        <DialogTitle>
          Missing Design Option Exclusion Justifications
        </DialogTitle>
        <Alert severity="warning">
          The justification for the exclusion of one or more design options has
          not been provided. This can be skipped for now, but users will be
          prompted again to justify any remaining exclusions before the analysis
          can be completed. Otherwise, return to the current step and complete
          the remaining justifications
        </Alert>
        {/*<DialogContent>*/}
        {/*    /!*<DialogContentText>*!/*/}
        {/*    /!*    The justification for the exclusion of one or more design options has not been provided.  This*!/*/}
        {/*    /!*    can be skipped for now, but users will be prompted again to justify any remaining exclusions*!/*/}
        {/*    /!*    before the analysis can be completed.  Otherwise, return to the current step and complete the*!/*/}
        {/*    /!*    remaining justifications*!/*/}
        {/*    /!*</DialogContentText>*!/*/}
        {/*</DialogContent>*/}
        <DialogActions>
          <Button onClick={handleCloseSkipExclusionDialog}>
            Return to Justifications
          </Button>
          <Button onClick={handleSkipToNext}>Skip for Now</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
