import { Skeleton, Stack } from "@mui/material";

export default function VolumesGridSkeleton() {
  const row = (
    <Stack direction="row" spacing={1}>
      <Skeleton height={60} width={150} />
      <Skeleton height={60} width={150} />
      <Skeleton height={60} width={150} />
      <Skeleton height={60} width={150} />
    </Stack>
  );

  return (
    <Stack spacing={1}>
      <Skeleton height={50} width={200} />
      {row}
      {row}
      {row}
      {row}
    </Stack>
  );
}
