import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Alert, Button, Divider, Fab, Stack, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ExportButton from "../Components/ExportButton";
import { LEFT_NAV_BAR_WIDTH } from "../style/styleConstants";
import SubmitOnlineButton from "../Components/SubmitOnlineButton";
import UnsavedChangesMessage from "./UnsavedChangesMessage";
import { NewAnalysisSaveMessage } from "./UnsavedChangesMessage";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CloseIcon from "@mui/icons-material/Close";
import CommentDialog from "../Components/CommentDialog";
import { isCommentDialogOpenAtom } from "../state/globalState";
import { useAtom } from "jotai";
import useUserSession from "../hooks/useUserSession";
import { useProjects } from "../hooks/useProjects";
import { currentProjectKeyAtom } from "../state/globalState";

export default function NavButtonBar({
  disableBack,
  disableSave,
  disableNext,
  disableReport,
  exportButtonDisabledTooltip,
  handleBack,
  handleNext,
  withSaveButtons = true,
  launchExportDialog,
  nextButtonDisabledTooltip,
  onCapacityReportClick,
  stepIndex,
  unlinkNext,
}) {
  const { isAuthenticated } = useUserSession();
  const { reviewerProjects } = useProjects();
  const [currentProjectKey] = useAtom(currentProjectKeyAtom);

  // if current project is in reviewer projects, then the user is a reviewer
  let isReviewer = reviewerProjects.some((project) => {
    return project.key === currentProjectKey;
  });

  let proceedButton;
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useAtom(
    isCommentDialogOpenAtom
  );

  if (stepIndex === 6) {
    proceedButton = (
      <Button
        sx={{ color: "white" }}
        variant="contained"
        color="secondary"
        onClick={handleNext}
        disabled={disableReport}
        startIcon={<FontAwesomeIcon icon={"fa-solid fa-file-chart-column"} />}
      >
        Generate Cost Report
      </Button>
    );
  } else {
    proceedButton = (
      <Tooltip
        title={disableNext ? nextButtonDisabledTooltip : ""}
        arrow
        placement="top"
      >
        <Button
          sx={{ color: "white" }}
          component={unlinkNext ? undefined : Link}
          to={`/analysis/step${stepIndex + 2}`}
          variant="contained"
          color="secondary"
          onClick={handleNext}
          disabled={disableNext}
        >
          Next
        </Button>
      </Tooltip>
    );
  }

  return (
    <Stack
      marginBottom={1}
      spacing={1}
      sx={{
        width: `calc(100vw - ${LEFT_NAV_BAR_WIDTH})`,
        maxHeight: "50px",
        position: "absolute",
        bottom: 0,
      }}
    >
      <Divider />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          {withSaveButtons && (
            <>
              <ExportButton
                disableExport={disableSave}
                disabledTooltip={exportButtonDisabledTooltip}
                launchExportDialog={launchExportDialog}
              />
              <SubmitOnlineButton
                disabled={disableSave || !isAuthenticated || isReviewer}
                tooltipText={
                  !isAuthenticated
                    ? "Please login to save projects online"
                    : isReviewer
                    ? "You are on review mode, if you want to save the changes, please export the analysis"
                    : undefined
                }
              />
            </>
          )}
          {!!onCapacityReportClick && (
            <Tooltip
              title={
                <span style={{ fontSize: "11pt" }}>
                  Generate VJuST analyses report (.pdf) for review.
                </span>
              }
              arrow
              placement="top"
            >
              <Button
                color="secondary"
                onClick={onCapacityReportClick}
                startIcon={<FontAwesomeIcon icon="fa-file-chart-column" />}
                sx={{ color: "white", marginLeft: "10px" }}
                variant="contained"
              >
                Generate Capacity Report
              </Button>
            </Tooltip>
          )}
          <NewAnalysisSaveMessage />
          <UnsavedChangesMessage />
        </Stack>
        {!!isCommentDialogOpen && <CommentDialog stepIndex={stepIndex} />}
        <Fab
          size="medium"
          sx={{ position: "fixed", right: "20px", bottom: "70px" }}
          onClick={() => setIsCommentDialogOpen(!isCommentDialogOpen)}
        >
          {isCommentDialogOpen ? <CloseIcon /> : <AddCommentIcon />}
        </Fab>
        <Stack direction="row" spacing={1}>
          <Button
            disabled={stepIndex === 0 || disableBack}
            component={Link}
            color="secondary"
            onClick={handleBack}
            to={`/analysis/step${stepIndex}`}
          >
            Back
          </Button>
          {proceedButton}
        </Stack>
      </Stack>
    </Stack>
  );
}

NavButtonBar.propTypes = {
  disableBack: PropTypes.bool,
  disableSave: PropTypes.bool,
  disableNext: PropTypes.bool,
  disableReport: PropTypes.bool,
  exportButtonDisabledTooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  withSaveButtons: PropTypes.bool,
  launchExportDialog: PropTypes.func.isRequired,
  nextButtonDisabledTooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onCapacityReportClick: PropTypes.func,
  stepIndex: PropTypes.number.isRequired,
  unlinkNext: PropTypes.bool,
};
