import { ReactNode, useEffect, useState } from "react";
import { Alert, Tab, Tabs, Typography } from "@mui/material";

import { useProjects } from "../hooks/useProjects";
import ProjectsGrid from "../Project/ProjectsGrid";

export default function AllUserProjectsGrid({
  title,
  withBoldOpenButton = false,
  withHelpText = true,
}: {
  title?: ReactNode;
  withBoldOpenButton?: boolean;
  withHelpText?: boolean;
}): JSX.Element {
  const [isFetchingOwnerProjects, setIsFetchingOwnerProjects] =
    useState<boolean>(false);
  const [isFetchingReviewerProjects, setIsFetchingReviewerProjects] =
    useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const getA11yProps = (index: number) => ({
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  });
  const {
    fetchReviewerProjects,
    fetchUserProjects,
    reviewerProjects,
    userProjects,
  } = useProjects();

  useEffect(() => {
    setIsFetchingOwnerProjects(true);
    setIsFetchingReviewerProjects(true);
    fetchUserProjects().then(() => setIsFetchingOwnerProjects(false));
    fetchReviewerProjects().then(() => setIsFetchingReviewerProjects(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {title ? (
        title
      ) : (
        <Typography variant="h5" sx={{ mb: 1 }}>
          My Projects
        </Typography>
      )}
      {/* add help text on project page to remind user to open the newest analysis */}
      {withHelpText && (
        <Alert severity="info">
          If you have multiple versions of the same project, please open the
          newest one.
        </Alert>
      )}
      <Tabs
        aria-label="tabs for viewing a user's projects and the projects they are assigned to review"
        onChange={(event, newTab) => setCurrentTab(newTab)}
        sx={{
          borderBottom: "1px solid #B1B1B1",
          marginBottom: "30px",
          marginTop: "40px",
          width: "100%",
        }}
        value={currentTab}
      >
        <Tab label="Owner" {...getA11yProps(0)} />
        <Tab label="Reviewer" {...getA11yProps(1)} />
      </Tabs>
      {currentTab === 0 && (
        <ProjectsGrid
          loading={isFetchingOwnerProjects}
          projects={userProjects}
          withBoldOpenButton={withBoldOpenButton}
        />
      )}
      {currentTab === 1 && (
        <ProjectsGrid
          loading={isFetchingReviewerProjects}
          projects={reviewerProjects}
          withBoldOpenButton={withBoldOpenButton}
        />
      )}
    </>
  );
}
