import { useState } from "react";
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangle, faUser } from "@fortawesome/pro-solid-svg-icons";

import { ROUTES, RoutesType } from "../Util/RouteConstants";
import useUserSession from "../hooks/useUserSession";
import { currentProjectKeyAtom } from "../state/globalState";
import { useAtom } from "jotai";

export default function UserProfileNav(): JSX.Element {
  const [, setCurrentProjectKey] = useAtom(currentProjectKeyAtom);
  const { isAuthenticated, logout, user } = useUserSession();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * navigates to a route and closes the menu popup
   */
  const navigateAndClose = (route: RoutesType) => {
    navigate(route);
    handleClose();
  };

  return isAuthenticated ? (
    <>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        aria-label="logged in user details"
        color="inherit"
        onClick={handleMenu}
      >
        <Stack direction="row" spacing={1}>
          <FontAwesomeIcon icon={faUser} />
          <FontAwesomeIcon icon={faTriangle} rotation={180} width={8} />
        </Stack>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        open={!!anchorEl}
      >
        {[
          <MenuItem
            key="myProjects"
            onClick={() => navigateAndClose(ROUTES.MY_PROJECTS.route)}
          >
            My projects
          </MenuItem>,
          <MenuItem
            key="profile"
            onClick={() => navigateAndClose(ROUTES.PROFILE.route)}
          >
            Profile
          </MenuItem>,
          ...(user?.admin
            ? [
                <MenuItem
                  key="admin"
                  onClick={() => navigateAndClose(ROUTES.ADMIN.route)}
                >
                  Admin
                </MenuItem>,
              ]
            : []),
          <MenuItem
            key="logout"
            onClick={() => {
              logout();
              setCurrentProjectKey(undefined);
              navigateAndClose(ROUTES.HOME.route);
            }}
          >
            Logout
          </MenuItem>,
          <Stack key="logged in as" marginTop={2} paddingX={2} spacing={2}>
            <Divider />
            <Stack>
              <Typography color="text.secondary" variant="caption">
                Logged in as
              </Typography>
              <Typography variant="caption">{user?.email}</Typography>
            </Stack>
          </Stack>,
        ]}
      </Menu>
    </>
  ) : (
    <Link style={{ color: "white" }} to={ROUTES.LOGIN.route}>
      Login
    </Link>
  );
}
