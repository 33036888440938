import { Alert, Box, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import PasswordInput from "./PasswordInput";
import { ErrorsType, RegisterType } from "./formTypes";
import { UserService } from "../../service/userService";
import { ObjectType } from "../../api/dataTypes";
import FormSubmitStatus from "../FormSubmitStatus";

export default function PasswordSection({
  clearErrors,
  confirmInputLabel = "Confirm password",
  confirmInputName = "confirmPassword",
  errors,
  formData,
  inputLabel = "Password",
  inputName = "password",
  isRequired = true,
  register,
  setError,
  setValue,
}: {
  clearErrors: Function;
  confirmInputLabel?: string;
  confirmInputName?: string;
  errors: ErrorsType;
  formData: ObjectType;
  inputLabel?: string;
  inputName?: string;
  isRequired?: boolean;
  register: RegisterType;
  setError: Function;
  setValue: Function;
}): JSX.Element {
  const [generatedPassword, setGeneratedPassword] = useState<string>("");
  const [canGeneratePasswords, setCanGeneratePasswords] =
    useState<boolean>(true);

  useEffect(() => {
    if (generatedPassword) {
      setValue(inputName, generatedPassword);
      setValue(confirmInputName, generatedPassword);
    }
  }, [confirmInputName, generatedPassword, inputName, setValue]);

  const hasMatchingPasswords =
    formData[inputName] === formData[confirmInputName];

  const PASSWORD_MISMATCH = "passwordMismatch";
  useEffect(() => {
    if (hasMatchingPasswords) {
      clearErrors(PASSWORD_MISMATCH);
    } else {
      setError(PASSWORD_MISMATCH, { message: "Passwords must match" });
    }
  }, [clearErrors, hasMatchingPasswords, setError]);

  const commonTextInputProps = {
    register,
    errors,
    isRequired: true,
    fullWidth: true,
  };
  const shrink = generatedPassword ? true : undefined;
  return (
    <>
      <PasswordInput
        {...commonTextInputProps}
        isRequired={isRequired}
        label={inputLabel}
        name={inputName}
        shrink={shrink}
      />
      <PasswordInput
        {...commonTextInputProps}
        isRequired={isRequired}
        label={confirmInputLabel}
        name={confirmInputName}
        shrink={shrink}
      />
      {errors[PASSWORD_MISMATCH] &&
      formData[inputName] &&
      formData[confirmInputName] ? (
        <Alert severity="error" sx={{ minWidth: "100%" }}>
          {errors.passwordMismatch.message}
        </Alert>
      ) : null}
      <Stack spacing={2}>
        <Box>
          <Button
            onClick={async () => {
              const response = await UserService.generatePassword();
              if (response.isError) {
                setCanGeneratePasswords(false);
              } else {
                setCanGeneratePasswords(true);
                setGeneratedPassword(response?.data);
              }
            }}
            variant="outlined"
          >
            Generate password
          </Button>
        </Box>
        <Alert severity="info" sx={{ minWidth: "100%" }}>
          Valid passwords have:
          <ul style={{ paddingLeft: "15px" }}>
            <li>at least 14 characters</li>
            <li>at least 1 uppercase letter</li>
            <li>at least 1 lowercase letter</li>
            <li>at least 1 non-alphanumeric character</li>
            <li>at least 1 number</li>
            <li>not been used as one of your recent VJuST passwords</li>
          </ul>
        </Alert>
        {!canGeneratePasswords && (
          <FormSubmitStatus
            response={{
              isError: true,
              message:
                "Cannot generate passwords at this time. Please type your password.",
            }}
          />
        )}
      </Stack>
    </>
  );
}
