import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_EW, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/** Diverging Diamond Interchange computational class. Extends the Intersection parent class */
export class DivergingDiamond extends Intersection {
  /**
   * Constructor for the DivergingDiamond class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} freewayDirection - Optional string identifier for the freeway direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, freewayDirection = DIR_EW) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
    // [Default] Intersection specific lane configuration
    // Can use this area to implement default inputs for each intersection
    // this.Z5EBLlanes = 1; // # EB Left lanes
    this.freewayDirection = freewayDirection;

    this.LaneConfig = {
      Z1: {
        Fwy2RTLanes: 1, // FwyDir == EW, Fwy2 = WB, FwyDir == NS, Fwy2 = SB
      },
      Z2: {
        Fwy2LTLanes: 1, // FwyDir == EW, Fwy2 = WB, FwyDir == NS, Fwy2 = SB
        // Cross2ThruLanes: 0 // FwyDir == EW, Cross2 = SB, FwyDir == NS, Cross2 = EB
      },
      Z3: {
        Cross2SharedThruLTLanes: 1, // FwyDir == EW, Cross2 = SB, FwyDir == NS, Cross2 = EB
        Cross2RTLanes: 1,
        Cross1ThruLanes: 1, // FwyDir == EW, Cross1 = NB, FwyDir == NS, Cross1 = WB
      },
      Z4: {
        Cross1SharedThruLTLanes: 1, // FwyDir == EW, Cross1 = NB, FwyDir == NS, Cross1 = WB
        Cross1RTLanes: 1,
        Cross2ThruLanes: 1, // FwyDir == EW, Cross2 = SB, FwyDir == NS, Cross2 = EB
      },
      Z5: {
        Fwy1LTLanes: 1, // FwyDir == EW, Fwy1 = EB, FwyDir == NS, Fwy1 = NB
        // Cross1ThruLanes: 1 // FwyDir == EW, Cross1 = NB, FwyDir == NS, Cross1 = WB
      },
      Z6: {
        Fwy1RTLanes: 1, // FwyDir == EW, Fwy1 = EB, FwyDir == NS, Fwy1 = NB
      },
    };
  }

  /**
   * Function to get the DEFAULT inputs available for the interchange. This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A diverging diamond interchange has six zones (Z1-Z6) and is dependent of on the specified freeway direction.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z1: {
        Fwy2: { RT: 1 }, // FwyDir == EW, Fwy2 = WB, FwyDir == NS, Fwy2 = SB
      },
      Z2: {
        Fwy2: { LT: 1 }, // FwyDir == EW, Fwy2 = WB, FwyDir == NS, Fwy2 = SB
      },
      Z3: {
        Cross2: { T: 1, RT: 1 }, // FwyDir == EW, Cross2 = SB, FwyDir == NS, Cross2 = EB
        Cross1: { T: 1 }, // FwyDir == EW, Cross1 = NB, FwyDir == NS, Cross1 = WB
      },
      Z4: {
        Cross1: { T: 1, RT: 1 }, // FwyDir == EW, Cross1 = NB, FwyDir == NS, Cross1 = WB
        Cross2: { T: 1 }, // FwyDir == EW, Cross2 = SB, FwyDir == NS, Cross2 = EB
      },
      Z5: {
        Fwy1: { LT: 1 }, // FwyDir == EW, Fwy1 = EB, FwyDir == NS, Fwy1 = NB
      },
      Z6: {
        Fwy1: { RT: 1 }, // FwyDir == EW, Fwy1 = EB, FwyDir == NS, Fwy1 = NB
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Z1.Fwy2RTLanes = laneConfigInputs.Z1.Fwy2.RT;
    this.LaneConfig.Z2.Fwy2LTLanes = laneConfigInputs.Z2.Fwy2.LT;
    this.LaneConfig.Z3.Cross2SharedThruLTLanes = laneConfigInputs.Z3.Cross2.T;
    this.LaneConfig.Z3.Cross2RTLanes = laneConfigInputs.Z3.Cross2.RT;
    this.LaneConfig.Z3.Cross1ThruLanes = laneConfigInputs.Z3.Cross1.T;
    this.LaneConfig.Z4.Cross1SharedThruLTLanes = laneConfigInputs.Z4.Cross1.T;
    this.LaneConfig.Z4.Cross1RTLanes = laneConfigInputs.Z4.Cross1.RT;
    this.LaneConfig.Z4.Cross2ThruLanes = laneConfigInputs.Z4.Cross2.T;
    this.LaneConfig.Z5.Fwy1LTLanes = laneConfigInputs.Z5.Fwy1.LT;
    this.LaneConfig.Z6.Fwy1RTLanes = laneConfigInputs.Z6.Fwy1.RT;
  }

  getLaneConfigInputs() {
    return {
      Z1: {
        Fwy2: { RT: this.LaneConfig.Z1.Fwy2RTLanes }, // FwyDir == EW, Fwy2 = WB, FwyDir == NS, Fwy2 = SB
      },
      Z2: {
        Fwy2: { LT: this.LaneConfig.Z2.Fwy2LTLanes }, // FwyDir == EW, Fwy2 = WB, FwyDir == NS, Fwy2 = SB
      },
      Z3: {
        Cross2: {
          SharedThruLT: this.LaneConfig.Z3.Cross2SharedThruLTLanes,
          RT: this.LaneConfig.Z3.Cross2RTLanes,
        }, // FwyDir == EW, Cross2 = SB, FwyDir == NS, Cross2 = EB
        Cross1: { T: this.LaneConfig.Z3.Cross1ThruLanes }, // FwyDir == EW, Cross1 = NB, FwyDir == NS, Cross1 = WB
      },
      Z4: {
        Cross1: {
          SharedThruLT: this.LaneConfig.Z4.Cross1SharedThruLTLanes,
          RT: this.LaneConfig.Z4.Cross1RTLanes,
        }, // FwyDir == EW, Cross1 = NB, FwyDir == NS, Cross1 = WB
        Cross2: { T: this.LaneConfig.Z4.Cross2ThruLanes }, // FwyDir == EW, Cross2 = SB, FwyDir == NS, Cross2 = EB
      },
      Z5: {
        Fwy1: { RT: this.LaneConfig.Z5.Fwy1LTLanes }, // FwyDir == EW, Fwy1 = EB, FwyDir == NS, Fwy1 = NB
      },
      Z6: {
        Fwy1: { LT: this.LaneConfig.Z6.Fwy1RTLanes }, // FwyDir == EW, Fwy1 = EB, FwyDir == NS, Fwy1 = NB
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_DDI;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateInterchangeVolumes(this.freewayDirection);

    // Set up volumes for Z1, Z2, and Z3
    let intx1 = {
      Fwy2: {
        RT: volumes.Fwy2.RT,
      },
    };
    let intx2 = {
      Fwy2: {
        LT: volumes.Fwy2.LT,
      },
      Cross2: {
        T: volumes.Cross2.LT + volumes.Cross2.T,
      },
    };
    let intx3 = {
      Cross2: {
        LT: volumes.Cross2.LT,
        T: volumes.Cross2.T,
        RT: volumes.Cross2.RT,
      },
      Cross1: {
        T: volumes.Fwy1.LT + volumes.Cross1.T,
      },
    };

    // Determine Z1 Critical Lane Volume
    const Z1CLV = Math.round(
      errDiv(intx3.Cross1.T, this.LaneConfig.Z3.Cross1ThruLanes) +
        errDiv(intx1.Fwy2.RT, this.RTAF, this.LaneConfig.Z1.Fwy2RTLanes)
    );
    const Z1VC = Z1CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 1 -------");
    }
    if (DEBUG) {
      console.log(
        "Z1 Fwy2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "WB" : "SB") +
          "): 0, 0, " +
          intx1.Fwy2.RT
      );
    }
    if (DEBUG) {
      console.log("Z1 CLV: " + Z1CLV);
    }
    if (DEBUG) {
      console.log("Z1 VC: " + Z1VC);
    }

    // Determine Z2 Critical Lane Volume
    const Z2CLV = Math.round(
      errDiv(intx2.Fwy2.LT, this.LTAF, this.LaneConfig.Z2.Fwy2LTLanes) +
        errDiv(intx2.Cross2.T, this.LaneConfig.Z3.Cross2SharedThruLTLanes)
    );
    const Z2VC = Z2CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 2 -------");
    }
    if (DEBUG) {
      console.log(
        "Z2 Fwy2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "WB" : "SB") +
          "): " +
          intx2.Fwy2.LT +
          ", 0, 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z2 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): 0, " +
          intx2.Cross2.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log("Z2 CLV: " + Z2CLV);
    }
    if (DEBUG) {
      console.log("Z2 VC: " + Z2VC);
    }

    const Z3CLV = Math.round(
      errDiv(intx3.Cross1.T, this.LaneConfig.Z3.Cross1ThruLanes) +
        errDiv(intx2.Cross2.T, this.LaneConfig.Z3.Cross2SharedThruLTLanes)
    );
    const Z3VC = Z3CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 3 -------");
    }
    if (DEBUG) {
      console.log(
        "Z3 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): 0, " +
          intx3.Cross1.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z3 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): " +
          intx3.Cross2.LT +
          ", " +
          intx3.Cross2.T +
          ", " +
          intx3.Cross2.RT
      );
    }
    if (DEBUG) {
      console.log("Z3 CLV: " + Z3CLV);
    }
    if (DEBUG) {
      console.log("Z3 VC: " + Z3VC);
    }

    // Set up volumes for Z4, Z5, and Z6
    let intx6 = {
      Fwy1: {
        RT: volumes.Fwy1.RT,
      },
    };
    let intx5 = {
      Fwy1: {
        LT: volumes.Fwy1.LT,
      },
      Cross1: {
        T: volumes.Cross1.LT + volumes.Cross1.T,
      },
    };
    let intx4 = {
      Cross1: {
        LT: volumes.Cross1.LT,
        T: volumes.Cross1.T,
        RT: volumes.Cross1.RT,
      },
      Cross2: {
        T: volumes.Fwy2.LT + volumes.Cross2.T,
      },
    };

    // Determine Z6 Critical Lane Volume
    const Z6CLV = Math.round(
      errDiv(intx4.Cross2.T, this.LaneConfig.Z4.Cross2ThruLanes) +
        errDiv(intx6.Fwy1.RT, this.RTAF, this.LaneConfig.Z6.Fwy1RTLanes)
    );
    const Z6VC = Z6CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 6 -------");
    }
    if (DEBUG) {
      console.log(
        "Z6 Fwy1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "EB" : "NB") +
          "): 0, 0, " +
          intx6.Fwy1.RT
      );
    }
    if (DEBUG) {
      console.log("Z6 CLV: " + Z6CLV);
    }
    if (DEBUG) {
      console.log("Z6 VC: " + Z6VC);
    }

    // Determine Z2 Critical Lane Volume
    const Z5CLV = Math.round(
      errDiv(intx5.Fwy1.LT, this.LTAF, this.LaneConfig.Z5.Fwy1LTLanes) +
        errDiv(intx5.Cross1.T, this.LaneConfig.Z4.Cross1SharedThruLTLanes)
    );
    const Z5VC = Z5CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 5 -------");
    }
    if (DEBUG) {
      console.log(
        "Z5 Fwy1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "EB" : "NB") +
          "): " +
          intx5.Fwy1.LT +
          ", 0, 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z5 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): 0, " +
          intx5.Cross1.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log("Z5 CLV: " + Z5CLV);
    }
    if (DEBUG) {
      console.log("Z5 VC: " + Z5VC);
    }

    const Z4CLV = Math.round(
      errDiv(intx4.Cross2.T, this.LaneConfig.Z4.Cross2ThruLanes) +
        errDiv(intx5.Cross1.T, this.LaneConfig.Z4.Cross1SharedThruLTLanes)
    );
    const Z4VC = Z4CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 4 -------");
    }
    if (DEBUG) {
      console.log(
        "Z4 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): 0, " +
          intx4.Cross2.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): " +
          intx4.Cross1.LT +
          ", " +
          intx4.Cross1.T +
          ", " +
          intx4.Cross1.RT
      );
    }
    if (DEBUG) {
      console.log("Z4 CLV: " + Z4CLV);
    }
    if (DEBUG) {
      console.log("Z4 VC: " + Z4VC);
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: Z1VC,
        CLV: Z1CLV,
      },
      Z2: {
        VC: Z2VC,
        CLV: Z2CLV,
      },
      Z3: {
        VC: Z3VC,
        CLV: Z3CLV,
      },
      Z4: {
        VC: Z4VC,
        CLV: Z4CLV,
      },
      Z5: {
        VC: Z5VC,
        CLV: Z5CLV,
      },
      Z6: {
        VC: Z6VC,
        CLV: Z6CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    const countCrossing = 2;
    const countMerging = 8;
    const countDiverging = 8;

    return (
      this.globalParams.conflict.wCrossing * countCrossing +
      this.globalParams.conflict.wMerging * countMerging +
      this.globalParams.conflict.wDiverging * countDiverging
    );
  }

  getAccommodation() {
    return "-";
  }

  getPlanningLevelCostStr() {
    return "$$$";
  }

  isVerified() {
    return true;
  }
}
