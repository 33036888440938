import * as React from "react";
import { Link } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VDOTColorHelper } from "../style/kaiTheme";
import AppBar from "@mui/material/AppBar";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: VDOTColorHelper.VDOT_Blue,
  }),
  ...(ownerState.completed && {
    backgroundColor: VDOTColorHelper.VDOT_Blue_Light,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed ? (
        <FontAwesomeIcon icon={"fa-solid fa-check"} />
      ) : (
        <Typography sx={{ fontSize: 14 }}>{props.icon}</Typography>
      )}
    </ColorlibStepIconRoot>
  );
}

export default function AnalysisStepper({ activeStep, setActiveStep, steps }) {
  const handleReset = () => {
    setActiveStep(0);
  };
  function getOptionalText(index) {
    if (index === 4) {
      return <Typography variant="caption">Last VJuST step</Typography>;
    } else if (index === 6) {
      return <Typography variant="caption">Last VJuST-C step</Typography>;
    }
    return null;
  }

  return (
    <AppBar
      color="default"
      position="static"
      style={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        marginRight: "50px",
        overflow: "auto",
      }}
    >
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{
          backgroundColor: "transparent",
          paddingLeft: "5px",
          minHeight: "fit-content",
        }}
      >
        {steps.map(({ label, route }, thisStep) => (
          <Step key={`${label}-${route}`} color="secondary">
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              optional={getOptionalText(thisStep)}
            >
              {thisStep < activeStep ? (
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to={route}
                >
                  {label}
                </Link>
              ) : (
                label
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </AppBar>
  );
}
