import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  Tooltip,
} from "@mui/material";

import NavButtonBar from "./NavButtonBar";
import { ProjectKeyMap } from "./ProjectHelper";
import VcTable from "../Components/VcTable";
import CapacityReport from "../Components/generateReport/CapacityReport";
import ExportDialog from "../Project/ExportDialog";
import { getICapCsvString } from "../Util/ExportHelpers";

const STEP_INDEX = 4;

export default function CapacityAnalysisPanel({
  launchExportDialog,
  project = {},
  updateActiveStep,
  defaultVjustValues,
}) {
  const [showCapacityReport, setShowCapacityReport] = useState(false);
  const closeCapacityReport = () => setShowCapacityReport(false);

  const [showICapDialog, setShowICapDialog] = useState(false);

  useEffect(() => {
    updateActiveStep(STEP_INDEX);
  });

  const [demandScenario, setDemandScenario] = useState(0);
  const handleDemandScenarioChange = (event) => {
    setDemandScenario(event.target.value);
  };

  const getFileNameWithScenario = () => {
    const projectName =
      project[ProjectKeyMap.ID_PROJECT_INFO]?.[
        ProjectKeyMap.ID_PI_PROJECT_NAME
      ];

    const scenarioName =
      project[ProjectKeyMap.ID_GLOBAL_INPUTS][
        ProjectKeyMap.ID_GI_VOL_SCENARIOS
      ]?.[demandScenario]?.[ProjectKeyMap.ID_GI_SCN_NAME];

    const date = new Date();
    return `${
      projectName && `${projectName}_`
    }${scenarioName}_capacity report_${date.toLocaleDateString()}`;
  };

  return (
    <div
      style={{
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "20px",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          height: "calc(100% - 50px)",
          overflow: "auto",
          paddingBottom: "20px",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Volume-to-Capacity Analysis Results
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ height: "10px" }}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", height: "48px" }}
          >
            <Typography sx={{ marginRight: "10px" }} variant="body1">
              <em>Scenario: </em>
            </Typography>
            <div
              style={{ flexGrow: 1, marginRight: "10px", maxWidth: "600px" }}
            >
              <FormControl size="small" fullWidth>
                <Select
                  labelId="scenario-select-helper-label"
                  id="scenario-select-input"
                  value={demandScenario}
                  onChange={handleDemandScenarioChange}
                >
                  {project[ProjectKeyMap.ID_GLOBAL_INPUTS][
                    ProjectKeyMap.ID_GI_VOL_SCENARIOS
                  ].map((scenario, index) => (
                    <MenuItem key={"scenario-option-" + index} value={index}>
                      {scenario[ProjectKeyMap.ID_GI_SCN_NAME]}
                    </MenuItem>
                  ))}
                  <MenuItem key={"scenario-option-max"} value={-1}>
                    Max Across All Demand Scenarios
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <Tooltip
              title={
                <span style={{ fontSize: "11pt" }}>
                  Export a excel spreadsheet (.csv) for iCap Stage 1 analysis.
                </span>
              }
              arrow
              placement="top"
            >
              <Button
                onClick={() => setShowICapDialog(true)}
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                Export to iCap
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Paper
          variant="outlined"
          sx={{
            width: "100%",
            overflow: "auto",
            marginTop: "10px",
            height: "calc(100% - 165px)",
          }}
        >
          <VcTable
            demandScenario={demandScenario}
            project={project}
            defaultAccommodation={
              defaultVjustValues ? defaultVjustValues.accommodation : null
            }
            defaultConflictPoints={
              defaultVjustValues ? defaultVjustValues.conflictPoints : null
            }
            defaultPlanningLevelCost={
              defaultVjustValues ? defaultVjustValues.planningLevelCost : null
            }
          />
        </Paper>
      </div>
      <NavButtonBar
        stepIndex={STEP_INDEX}
        launchExportDialog={launchExportDialog}
        disableNext={false}
        nextButtonDisabledTooltip="This step is the end of screening level."
        onCapacityReportClick={() => setShowCapacityReport(true)}
      />
      {showICapDialog && (
        <ExportDialog
          content="Export a .csv file for use in the VDOT iCap spreadsheet."
          dataBlob={
            new Blob(
              [
                getICapCsvString({
                  demandScenario,
                  globalInputs: project[ProjectKeyMap.ID_GLOBAL_INPUTS],
                  projectAlternatives:
                    project[ProjectKeyMap.ID_ALTERNATIVES_LIST],
                }),
              ],
              {
                type: "text/csv",
                name: "vjust-web-tool.csv",
              }
            )
          }
          defaultFileName={getFileNameWithScenario()}
          endAdornment=".csv"
          handleClose={() => setShowICapDialog(false)}
          open
          title="Export to iCap"
        />
      )}
      {showCapacityReport && (
        <Dialog
          className="printViewOverflowVisible"
          onClose={closeCapacityReport}
          open
          fullScreen
          sx={{ overflowY: "auto" }}
        >
          <CapacityReport onClose={closeCapacityReport} project={project} />
        </Dialog>
      )}
    </div>
  );
}

CapacityAnalysisPanel.propTypes = {
  launchExportDialog: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
};
