import React from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { VDOTColorHelper } from "../style/kaiTheme";
import NavButtonBar from "./NavButtonBar";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import SelectionMap from "./SelectionMap";
import Dialog from "@mui/material/Dialog";
import { ProjectKeyMap } from "./ProjectHelper";
import { getBaseMaps } from "../Util/MapUtils";
import Paper from "@mui/material/Paper";
import TooltipIcon from "../Components/TooltipIcon";
import Tooltip from "@mui/material/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const STEP_INDEX = 0;
const rowStyle = { display: "flex", marginBottom: "10px" };
const col1Style = {
  width: "250px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
const col1StyleB = {
  width: "250px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
};
const col2Style = { flexGrow: 1 };

export default function ProjectInfoPanel(props) {
  React.useEffect(() => {
    props.updateActiveStep(STEP_INDEX);
  });

  const [projectName, setProjectName] = React.useState(
    props.projectInfo[ProjectKeyMap.ID_PI_PROJECT_NAME] || ""
  );
  const [analyst, setAnalyst] = React.useState(
    props.projectInfo[ProjectKeyMap.ID_PI_ANALYST] || ""
  );
  const [agency, setAgency] = React.useState(
    props.projectInfo[ProjectKeyMap.ID_PI_AGENCY] || ""
  );
  const [notes, setNotes] = React.useState(
    props.projectInfo[ProjectKeyMap.ID_PI_NOTES] || ""
  );
  const [ewFacilityName, setEWFacilityName] = React.useState(
    props.projectInfo[ProjectKeyMap.ID_PI_MAJOR_ST] || ""
  );
  const [nsFacilityName, setNSFacilityName] = React.useState(
    props.projectInfo[ProjectKeyMap.ID_PI_MINOR_ST] || ""
  );
  const [projectLocation, setProjectLocation] = React.useState(
    props.projectInfo[ProjectKeyMap.ID_PI_GEOLOCATION] || null
  );
  const [date, setDate] = React.useState(
    props.projectInfo[ProjectKeyMap.ID_PI_DATE] ||
      new Date().toLocaleDateString()
  );

  const updateSavedProjectInfo = React.useCallback(() => {
    props.updateProjectInfo({
      [ProjectKeyMap.ID_PI_PROJECT_NAME]: projectName,
      [ProjectKeyMap.ID_PI_ANALYST]: analyst,
      [ProjectKeyMap.ID_PI_AGENCY]: agency,
      [ProjectKeyMap.ID_PI_NOTES]: notes,
      [ProjectKeyMap.ID_PI_MAJOR_ST]: ewFacilityName,
      [ProjectKeyMap.ID_PI_MINOR_ST]: nsFacilityName,
      [ProjectKeyMap.ID_PI_GEOLOCATION]: projectLocation,
      [ProjectKeyMap.ID_PI_DATE]: date,
    });
  }, [
    agency,
    analyst,
    ewFacilityName,
    notes,
    nsFacilityName,
    projectLocation,
    date,
    projectName,
    props,
  ]);

  // Real-time save on dependency change
  React.useEffect(() => {
    updateSavedProjectInfo();
  }, [
    agency,
    analyst,
    ewFacilityName,
    notes,
    nsFacilityName,
    projectLocation,
    date,
    projectName,
    updateSavedProjectInfo,
  ]);

  const [mapLocationPinned, setMapLocationPinned] = React.useState(false);
  const [mapDialogVisible, setMapDialogVisible] = React.useState(false);
  const showMapDialog = () => {
    setMapDialogVisible(true);
  };
  const handleMapDialogClose = () => {
    setMapDialogVisible(false);
  };

  const setAnalysisLocation = (latLonObj) => {
    setProjectLocation(latLonObj);
  };

  React.useEffect(() => {
    setMapLocationPinned(
      projectLocation !== null && projectLocation !== undefined
    );
  }, [projectLocation]);

  const [miniMapComponent, setMiniMapComponent] = React.useState(null);
  function reduceMapMarkerUpdate(state, newMarker) {
    if (state !== null) {
      state.remove();
    }
    return newMarker;
  }
  // TODO @elliotcobb fix this
  // eslint-disable-next-line no-unused-vars
  const [mapMarker, updateMapMarker] = React.useReducer(
    reduceMapMarkerUpdate,
    null,
    undefined
  );

  React.useEffect(() => {
    if (mapLocationPinned) {
      if (miniMapComponent === null) {
        let baseMaps = getBaseMaps();
        const miniMap = L.map("mini-location-map", {
          center: [37.54129, -77.434769],
          zoom: 11,
          layers: [baseMaps.OpenStreetMaps],
          zoomControl: true,
          dragging: false,
          scrollWheelZoom: false,
        });
        setMiniMapComponent(miniMap);
      } else {
        if (projectLocation !== null) {
          let marker = L.marker(projectLocation).addTo(miniMapComponent);
          miniMapComponent.panTo(projectLocation);
          updateMapMarker(marker);
        } else {
          updateMapMarker(null);
        }
      }
    } else {
      if (miniMapComponent !== null) {
        miniMapComponent.off();
        miniMapComponent.remove();
        setMiniMapComponent(null);
      }
    }
  }, [mapLocationPinned, miniMapComponent, projectLocation]);

  return (
    <div
      style={{
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "20px",
        height: "100%",
        position: "relative",
      }}
    >
      <div style={{ height: "calc(100% - 50px)" }}>
        <Grid container spacing={1} sx={{ height: "100%" }}>
          <Grid item xs={12} sx={{ height: "3em" }}>
            <Typography variant="h5">VJuST Project Information</Typography>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ height: "calc(100% - 3em)", overflow: "auto" }}
          >
            <Grid container spacing={1} sx={{ paddingBottom: "20px" }}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <em>
                    Please enter identifying information for the project.
                    Subsequent steps will guide the user through the selection,
                    analysis, and comparison of intersection alternatives.
                    <br />
                    <br />
                    Projects can be exported for documentation and/or returned
                    to later at any time using the{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        color: VDOTColorHelper.VDOT_Orange,
                      }}
                    >
                      Export Analysis
                    </span>{" "}
                    button in the bottom left of the tool.
                  </em>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider variant="middle" />
              </Grid>
              <Grid item xs={12}>
                <div style={rowStyle}>
                  <div style={col1Style}>
                    <b>Project Name</b>
                  </div>
                  <div style={col2Style}>
                    <TextField
                      style={{ width: "100%" }}
                      name="project-name"
                      variant="outlined"
                      size="small"
                      value={projectName}
                      onChange={(e) => {
                        setProjectName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={col1Style}>
                    <b>Project Analyst</b>
                  </div>
                  <div style={col2Style}>
                    <TextField
                      style={{ width: "100%" }}
                      name="project-analyst"
                      variant="outlined"
                      size="small"
                      value={analyst}
                      onChange={(e) => {
                        setAnalyst(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={col1Style}>
                    <b>Project Agency</b>
                  </div>
                  <div style={col2Style}>
                    <TextField
                      style={{ width: "100%" }}
                      name="project-agency"
                      variant="outlined"
                      size="small"
                      value={agency}
                      onChange={(e) => {
                        setAgency(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={col1StyleB}>
                    <div style={{ display: "flex" }}>
                      <b>Additional Project Notes</b>
                      <TooltipIcon
                        title={
                          "Use this space to enter any additional notes, description, and justification for the project. This information will be printed in the analysis summary report."
                        }
                      />
                    </div>
                  </div>
                  <div style={col2Style}>
                    <TextField
                      style={{ width: "100%" }}
                      name="project-notes"
                      variant="outlined"
                      size="small"
                      value={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                      multiline
                      rows={4}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={col1Style}>
                    <div style={{ display: "flex" }}>
                      <b>E-W Facility Name</b>
                      <TooltipIcon
                        title={
                          <span>
                            <b style={{ textDecoration: "underline" }}>
                              E-W Facility Name
                            </b>
                            <br />
                            Enter the name of the facility/street that
                            represents the East-West directional facility. While
                            not all facilities will match the cardinal
                            directions exactly, it is up to the user to identify
                            the roadway that most closely aligns with or best
                            represents the directional layout.
                          </span>
                        }
                      />
                    </div>
                  </div>
                  <div style={col2Style}>
                    <TextField
                      value={ewFacilityName}
                      onChange={(evt) => {
                        setEWFacilityName(evt.target.value);
                      }}
                      label={"Facility/Street Name"}
                      size="small"
                      fullWidth
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={col1Style}>
                    <div style={{ display: "flex" }}>
                      <b>N-S Facility Name</b>
                      <TooltipIcon
                        title={
                          <span>
                            <b style={{ textDecoration: "underline" }}>
                              N-S Facility Name
                            </b>
                            <br />
                            Enter the name of the facility/street that
                            represents the North-South directional facility.
                            While not all facilities will match the cardinal
                            directions exactly, it is up to the user to identify
                            the roadway that most closely aligns with or best
                            represents the directional layout.
                          </span>
                        }
                      />
                    </div>
                  </div>
                  <div style={col2Style}>
                    <TextField
                      value={nsFacilityName}
                      onChange={(evt) => {
                        setNSFacilityName(evt.target.value);
                      }}
                      label={"Facility/Street Name"}
                      size="small"
                      fullWidth
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={col1Style}>
                    <div style={{ display: "flex" }}>
                      <b>Date</b>
                    </div>
                  </div>
                  <div style={col2Style}>
                    <TextField
                      value={date}
                      onChange={(evt) => {
                        setDate(evt.target.value);
                      }}
                      label={"Date"}
                      size="small"
                      fullWidth
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={col1Style}>
                    {mapLocationPinned === false && (
                      <Paper
                        variant="outlined"
                        sx={{
                          width: "240px",
                          height: "200px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Tooltip
                          title={
                            <span style={{ fontSize: "11pt" }}>
                              {"No Location Set"}
                            </span>
                          }
                          placement="top"
                          arrow
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={"fa-solid fa-map-pin"}
                              size={"lg"}
                              style={{
                                color: mapLocationPinned
                                  ? VDOTColorHelper.VDOT_Orange
                                  : "rgba(0,0,0,0.26)",
                              }}
                            />
                          </span>
                        </Tooltip>
                      </Paper>
                    )}
                    {mapLocationPinned === true && (
                      <div
                        id="mini-location-map"
                        style={{
                          width: "240px",
                          height: "200px",
                          margin: "auto",
                        }}
                      />
                    )}
                  </div>
                  <div style={col2Style}>
                    <Tooltip
                      title={
                        <span style={{ fontSize: "11pt" }}>
                          Pin the location of the analysis to an existing (or
                          future) location on a map.
                        </span>
                      }
                      placement="top"
                      arrow
                    >
                      <Button variant={"contained"} onClick={showMapDialog}>
                        Set/View Location on Map
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <NavButtonBar
        stepIndex={STEP_INDEX}
        handleNext={updateSavedProjectInfo}
        launchExportDialog={props.launchExportDialog}
      />
      <Dialog
        open={mapDialogVisible}
        onClose={handleMapDialogClose}
        aria-labelledby="map-dialog-title"
        aria-describedby="map-dialog-description"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle id="map-dialog-title">
          {"Choose Analysis Location"}
        </DialogTitle>
        <DialogContent>
          <SelectionMap
            existingLocation={projectLocation}
            handleDialogClose={handleMapDialogClose}
            setAnalysisLocation={setAnalysisLocation}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

ProjectInfoPanel.propTypes = {
  projectInfo: PropTypes.object.isRequired,
  updateProjectInfo: PropTypes.func.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
  launchExportDialog: PropTypes.func.isRequired,
};
