const ERROR_CODES = {
  MAX_VALUE_ERROR: "MAX_VALUE_ERROR",
  MIN_VALUE_ERROR: "MIN_VALUE_ERROR",
  REQUIRED: "REQUIRED",
};

export const SELECT_ONE_OPTION = "-- Select one --";

const getErrorCode = ({ max, min, value }) => {
  if (isNaN(value)) return ERROR_CODES.REQUIRED;
  if (value < min) return ERROR_CODES.MIN_VALUE_ERROR;
  if (value > max) return ERROR_CODES.MAX_VALUE_ERROR;
  return;
};

export const getErrorMessage = ({ max, min, value }) => {
  const errorCode = getErrorCode({ max, min, value });
  if (errorCode === ERROR_CODES.MAX_VALUE_ERROR) return `Maximum: ${max}`;
  if (errorCode === ERROR_CODES.MIN_VALUE_ERROR) return `Minimum: ${min}`;
  if (errorCode === ERROR_CODES.REQUIRED) return "Required";
  return;
};
