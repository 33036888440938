import { Dialog, DialogContent, DialogTitle } from "@mui/material";

// couldn't figure out how to make the image import work in the tsx file, so this is a .jsx file for now
import volumesCopyPasteExampleImg from "../images/other/volumesCopyPasteExample.jpg";
import CloseButton from "./CloseButton";

export default function CopyPasteVolumesDialog(
  { onClose, open } /*: {
  onClose: () => void;
  open: boolean;
}*/
) {
  return (
    <Dialog open={open} onClose={onClose}>
      <CloseButton onClick={onClose} />
      <DialogTitle>Copy/Paste Volumes</DialogTitle>
      <DialogContent>
        <p>
          On the Global Inputs page you can paste a 4 by 4 grid of spreadsheet
          cells. Just paste anywhere on this page to enter volumes and truck
          percentages for the current scenario.
        </p>
        <p>
          Make sure to copy a grid of spreadsheet cells that has 4 columns of
          directions and 4 rows of movements/truck percentages:
        </p>
        <img
          src={volumesCopyPasteExampleImg}
          alt="Screenshot of someone highlighting a 4 by 4 grid of cells from a spreadsheet"
        />
      </DialogContent>
    </Dialog>
  );
}
