import { Button, Tooltip } from "@mui/material";
import { faCloudArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useState } from "react";

import SubmitOnlineDialog from "./SubmitOnlineDialog";
import EasySnackbar from "./EasySnackbar";

export default function SubmitOnlineButton({
  children = "Save Project Online",
  disabled,
  tooltipText,
}: {
  children: ReactNode;
  disabled: boolean;
  tooltipText?: string;
}): JSX.Element {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const handleClose = () => setShowDialog(false);

  return (
    <>
      <Tooltip title={tooltipText}>
        <div>
          <Button
            color="secondary"
            disabled={disabled}
            onClick={() => setShowDialog(true)}
            startIcon={<FontAwesomeIcon icon={faCloudArrowUp} />}
            variant="outlined"
          >
            {children}
          </Button>
        </div>
      </Tooltip>
      {showDialog && (
        <SubmitOnlineDialog
          setIsSnackbarOpen={setIsSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          handleClose={handleClose}
        />
      )}
      <EasySnackbar
        alertProps={{ children: snackbarMessage }}
        isOpen={isSnackbarOpen}
        setIsOpen={setIsSnackbarOpen}
      />
    </>
  );
}
