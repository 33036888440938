import React from "react";
import { useAtom } from "jotai";
import { currentProjectKeyAtom } from "../state/globalState";
import { CustomPaper } from "../Components/CustomPaper";
import { DisclaimerDialog } from "../Disclaimer/Disclaimer";
import AnalysisStepper from "./AnalysisStepper";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import ProjectInfoPanel from "./Step1ProjectInfo";
import {
  createEmptyProject,
  defaultTruckPCE,
  defaultTurnFactors,
  ProjectKeyMap,
  sessionKeys,
  VersionKeys,
} from "./ProjectHelper";
import GlobalInputsPanel from "./Step2GlobalInputs";
import ExportDialog from "../Project/ExportDialog";
import CapacityAnalysisPanel from "./Step5CapacityAnalysis";
import AlternativeConfigurationPane from "./Step3IntxSelectionV2";
import CostGlobalInputsPanel from "./Step6CostGlobalInputs";
import CostConfigurationPanel from "./Step7CostAnalysis";
import ExclusionJustificationPanel from "./Step4ExclusionJustification";
import {
  processVjustConfigCSV,
  processCostConfigCSV,
  readCSVFile,
} from "./defaultValuesCSVReader";
import DefaultGlobalCostInputsEditor from "./EditDefaults/DefaultGlobalCostInputsEditor";
import { EDIT_GLOBAL_DEFAULTS_ROUTE } from "../Util/RouteConstants";
import { ROUTES } from "../Util/RouteConstants";

const steps = [
  { label: "Project Information", route: ROUTES.STEP_1.route },
  { label: "Global Input Data", route: ROUTES.STEP_2.route },
  {
    label: "Design Option Selection",
    route: ROUTES.STEP_3.route,
  },
  {
    label: "Exclusion Justification",
    route: ROUTES.STEP_4.route,
  },
  { label: "VJuST Results", route: ROUTES.STEP_5.route },
  { label: "Cost Global Input Data", route: ROUTES.STEP_6.route },
  { label: "Cost Analysis & Results", route: ROUTES.STEP_7.route },
];

export default function Analysis() {
  let params = useParams();
  const isStartingNewProject = params["*"] === "new";
  const storedActiveStep =
    sessionStorage.getItem(sessionKeys.KEY_ACTIVE_STEP) || 0;
  const [activeStep, setActiveStep] = React.useState(
    isStartingNewProject ? 0 : parseInt(storedActiveStep)
  );
  // Update active step in session storage when active step changes
  React.useEffect(() => {
    sessionStorage.setItem(sessionKeys.KEY_ACTIVE_STEP, activeStep);
  }, [activeStep]);

  let navigate = useNavigate();

  const [defaultVjustValues, setDefaultVjustValues] = React.useState({});
  const [defaultCostValues, setDefaultCostValues] = React.useState({});
  const [project, setProject] = React.useState(null);
  const [, setCurrentProjectKey] = useAtom(currentProjectKeyAtom);

  // function to read the default value Vjust_config CSV files
  React.useEffect(() => {
    readCSVFile(
      process.env.PUBLIC_URL + "/default_values/Vjust_config.csv", //public\default_values\Vjust_config.csv
      processVjustConfigCSV,
      (csvData1) => {
        // First CSV file loaded successfully
        console.log("Vjust_config.csv loaded successfully:", csvData1);
        setDefaultVjustValues(csvData1);
      },
      (error) => {
        // Error loading the first CSV file
        console.error("Error loading Vjust_config.csv:", error);
      }
    );

    readCSVFile(
      process.env.PUBLIC_URL + "/default_values/Vjust_cost_config.csv", //public\default_values\Vjust_config.csv
      processCostConfigCSV,
      (csvData2) => {
        // First CSV file loaded successfully
        console.log("Vjust_cost_config.csv loaded successfully:", csvData2);
        setDefaultCostValues(csvData2);
      },
      (error) => {
        // Error loading the first CSV file
        console.error("Error loading Vjust_cost_config.csv:", error);
      }
    );
  }, []);

  // Create new project or Load project from session storage on page load
  React.useEffect(() => {
    let project;
    if (isStartingNewProject) {
      console.log("creating new project");
      project = createEmptyProject();
      setCurrentProjectKey(undefined);
    } else {
      console.log("getting project from session storage");
      project = sessionStorage.getItem(sessionKeys.KEY_PROJECT);
      try {
        project = JSON.parse(project);
      } catch (e) {
        project = null;
      }
      if (project === null) {
        console.log(
          "failed to get project from session storage, creating new empty project"
        );
        project = createEmptyProject();
      }
    }
    setProject(project);
    navigate(
      "/analysis/step" +
        ((typeof activeStep === "string" ? parseInt(activeStep) : activeStep) +
          1),
      { replace: true }
    );
    // TODO @elliotcobb fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update project in session storage when project changes
  React.useEffect(() => {
    if (project) {
      sessionStorage.setItem(sessionKeys.KEY_PROJECT, JSON.stringify(project));
    }
  }, [project]);

  // Update default values when read csv completed and defaultVjustValues is updated
  React.useEffect(() => {
    if (project && defaultVjustValues.suggestedTAF) {
      const globalInputs = project[ProjectKeyMap.ID_GLOBAL_INPUTS] ?? {};
      setProject((project) => ({
        ...project,
        [ProjectKeyMap.ID_GLOBAL_INPUTS]: {
          ...project[ProjectKeyMap.ID_GLOBAL_INPUTS],
          [ProjectKeyMap.ID_GI_TURN_FACTORS]:
            globalInputs[ProjectKeyMap.ID_GI_TURN_FACTORS],
          [ProjectKeyMap.ID_GI_TRUCK_PCE]:
            globalInputs[ProjectKeyMap.ID_GI_TRUCK_PCE],
          [ProjectKeyMap.ID_GI_CLV_LIMIT]:
            globalInputs[ProjectKeyMap.ID_GI_CLV_LIMIT],
          [ProjectKeyMap.ID_GI_LEFT_TURN_FACTOR]:
            globalInputs[ProjectKeyMap.ID_GI_LEFT_TURN_FACTOR],
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVjustValues]);

  // update disclaimer acknowledgement to project
  const updateDisclaimer = (acknowledged) => {
    setProject((project) => ({
      ...project,
      [ProjectKeyMap.ID_DISCLAIMER_ACKNOWLEDGED]: acknowledged,
    }));
  };

  // update project info to project
  const updateProjectInfo = (newProjectInfo) => {
    setProject((project) => ({
      ...project,
      [ProjectKeyMap.ID_PROJECT_INFO]: newProjectInfo,
    }));
  };

  // update capacity global inputs to project
  const assignGlobalInputsToProject = React.useCallback(
    (volumeScenarios, turnFactors, truckPCE, clvLimit) => {
      setProject((project) => ({
        ...project,
        [ProjectKeyMap.ID_GLOBAL_INPUTS]: {
          ...project[ProjectKeyMap.ID_GLOBAL_INPUTS],
          ...(volumeScenarios
            ? { [ProjectKeyMap.ID_GI_VOL_SCENARIOS]: volumeScenarios }
            : {}),
          [ProjectKeyMap.ID_GI_TURN_FACTORS]: turnFactors,
          [ProjectKeyMap.ID_GI_TRUCK_PCE]: truckPCE,
          [ProjectKeyMap.ID_GI_CLV_LIMIT]: clvLimit,
        },
      }));
    },
    []
  );

  // update list of alternatives intersections to project
  const assignAlternativesToProject = React.useCallback((alternativesList) => {
    setProject((project) => ({
      ...project,
      [ProjectKeyMap.ID_ALTERNATIVES_LIST]: alternativesList,
    }));
  }, []);

  // update list of exclusions to project
  const assignExclusionsToProject = React.useCallback((exclusionList) => {
    setProject((project) => ({
      ...project,
      [ProjectKeyMap.ID_EXCLUSION_MAP]: exclusionList,
    }));
  }, []);

  // update global cost inputs to project
  const assignGlobalCostInputsToProject = React.useCallback(
    (newGlobalCostInputs) => {
      setProject((project) => ({
        ...project,
        [ProjectKeyMap.ID_GLOBAL_COST_INPUTS]: newGlobalCostInputs,
      }));
    },
    []
  );

  // update list of intersection cost inputs to project
  const assignIntxCostsToProject = React.useCallback((IntxCostConfigsList) => {
    setProject((project) => ({
      ...project,
      [ProjectKeyMap.ID_ALTERNATIVES_COST_LIST]: IntxCostConfigsList,
    }));
  }, []);

  const [openExportDialog, setOpenExportDialog] = React.useState(false);

  const launchExportDialog = () => {
    setOpenExportDialog(true);
  };

  // function to get the project data blob for export
  function getProjectDataBlob() {
    let projectObject = {};

    projectObject[ProjectKeyMap.ID_EXPORT_DATE] = new Date();
    projectObject[ProjectKeyMap.ID_VERSION_NO] = VersionKeys.ID_CURRENT_VERSION;
    projectObject[ProjectKeyMap.ID_PROJECT] = project;

    return new Blob([JSON.stringify(projectObject)], {
      type: "application/json",
      name: "vjust-web-tool.json",
    });
  }

  // function to get the project file name for export
  const getFileName = () => {
    const projectName =
      project[ProjectKeyMap.ID_PROJECT_INFO]?.[
        ProjectKeyMap.ID_PI_PROJECT_NAME
      ];
    const projectVersion = project[ProjectKeyMap.ID_VERSION_NO];

    const date = new Date();
    return `vjust web tool v${projectVersion}_${
      projectName && `${projectName}`
    }_${date.toLocaleDateString()}`;
  };

  return (
    <React.Fragment>
      {project !== null && (
        <React.Fragment>
          <CustomPaper
            sx={{
              width: "100%",
              height: "calc(100% - 64px)",
              display: "flex",
              textAlign: "left",
            }}
          >
            <div style={{ minWidth: "210px", maxWidth: "210px" }}>
              <AnalysisStepper
                activeStep={activeStep || 0}
                setActiveStep={setActiveStep}
                steps={steps}
              />
            </div>
            <div style={{ flexGrow: 1, backgroundColor: "transparent" }}>
              <Routes>
                <Route
                  path={"/step1"}
                  element={
                    <ProjectInfoPanel
                      projectInfo={project[ProjectKeyMap.ID_PROJECT_INFO]}
                      updateProjectInfo={updateProjectInfo}
                      updateActiveStep={setActiveStep}
                      launchExportDialog={launchExportDialog}
                    />
                  }
                />
                <Route
                  path={"/step2"}
                  element={
                    <GlobalInputsPanel
                      defaultVjustValues={
                        defaultVjustValues.suggestedTAF &&
                        defaultVjustValues.truckPCE
                          ? defaultVjustValues
                          : {
                              suggestedTAF: defaultTurnFactors,
                              truckPCE: defaultTruckPCE,
                            }
                      }
                      projectGlobalInputs={
                        project[ProjectKeyMap.ID_GLOBAL_INPUTS]
                      }
                      assignGlobalInputsToProject={assignGlobalInputsToProject}
                      updateActiveStep={setActiveStep}
                      launchExportDialog={launchExportDialog}
                    />
                  }
                />
                <Route
                  path={"/step3"}
                  element={
                    <AlternativeConfigurationPane
                      projectGlobalInputs={
                        project[ProjectKeyMap.ID_GLOBAL_INPUTS]
                      }
                      projectAlternatives={
                        project[ProjectKeyMap.ID_ALTERNATIVES_LIST]
                      }
                      assignAlternativesToProject={assignAlternativesToProject}
                      updateActiveStep={setActiveStep}
                      launchExportDialog={launchExportDialog}
                      defaultVjustValues={defaultVjustValues}
                    />
                  }
                />
                <Route
                  path={"/step4"}
                  element={
                    <ExclusionJustificationPanel
                      projectAlternatives={
                        project[ProjectKeyMap.ID_ALTERNATIVES_LIST]
                      }
                      projectExclusions={
                        project[ProjectKeyMap.ID_EXCLUSION_MAP]
                      }
                      assignExclusionsToProject={assignExclusionsToProject}
                      updateActiveStep={setActiveStep}
                      launchExportDialog={launchExportDialog}
                    />
                  }
                />
                <Route
                  path="/step5"
                  element={
                    <CapacityAnalysisPanel
                      project={project}
                      updateActiveStep={setActiveStep}
                      launchExportDialog={launchExportDialog}
                      defaultVjustValues={defaultVjustValues}
                    />
                  }
                />
                <Route path={"/step6"}>
                  <Route
                    path=""
                    element={
                      <CostGlobalInputsPanel
                        projectGlobalCostInputs={
                          project[ProjectKeyMap.ID_GLOBAL_COST_INPUTS]
                        }
                        saveFormData={assignGlobalCostInputsToProject}
                        updateActiveStep={setActiveStep}
                        launchExportDialog={launchExportDialog}
                      />
                    }
                  />
                  <Route
                    path={EDIT_GLOBAL_DEFAULTS_ROUTE}
                    element={
                      <DefaultGlobalCostInputsEditor
                        initialFormValues={
                          project[ProjectKeyMap.ID_GLOBAL_COST_INPUTS]
                        }
                        saveFormData={assignGlobalCostInputsToProject}
                      />
                    }
                  />
                </Route>
                <Route
                  path={"/step7"}
                  element={
                    <CostConfigurationPanel
                      projectAlternatives={
                        project[ProjectKeyMap.ID_ALTERNATIVES_LIST]
                      }
                      projectGlobalCostInputs={
                        project[ProjectKeyMap.ID_GLOBAL_COST_INPUTS]
                      }
                      projectAlternativesCosts={
                        project[ProjectKeyMap.ID_ALTERNATIVES_COST_LIST]
                      }
                      projectInfo={project[ProjectKeyMap.ID_PROJECT_INFO]}
                      defaultCostValues={defaultCostValues}
                      assignIntxCostsToProject={assignIntxCostsToProject}
                      updateActiveStep={setActiveStep}
                      launchExportDialog={launchExportDialog}
                    />
                  }
                />
              </Routes>
            </div>
          </CustomPaper>
          <DisclaimerDialog
            acknowledged={
              project[ProjectKeyMap.ID_DISCLAIMER_ACKNOWLEDGED] || false
            }
            setAcknowledged={(value) => {
              updateDisclaimer(value);
            }}
          />
          <ExportDialog
            content="Export analysis project as a JSON file. This file can be imported into the tool to continue working on the project."
            dataBlob={getProjectDataBlob()}
            defaultFileName={getFileName()}
            handleClose={() => setOpenExportDialog(false)}
            open={openExportDialog}
            title="Export Project"
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
