import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { faFileImport } from "@fortawesome/pro-solid-svg-icons";

export default function ExportButton({
  disabledTooltip,
  disableExport,
  launchExportDialog,
}) {
  return (
    <Tooltip
      title={
        disableExport ? (
          disabledTooltip
        ) : (
          <span style={{ fontSize: "11pt" }}>
            In-progress analyses can be exported to a text-based file (.json)
            that will automatically be downloaded to the user's computer.
          </span>
        )
      }
      arrow
      placement="top"
    >
      <span>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faFileImport} />}
          onClick={launchExportDialog}
          disabled={disableExport}
        >
          Export Project
        </Button>
      </span>
    </Tooltip>
  );
}

ExportButton.propTypes = {
  disabledTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disableExport: PropTypes.bool,
  launchExportDialog: PropTypes.func.isRequired,
};
