import { Stack } from "@mui/material";

import { ApiResponse, CommentType, CommentTypeToLabel } from "../api/dataTypes";
import SelectInput from "../Components/reactHookFormComponents/SelectInput";
import TextInput from "../Components/reactHookFormComponents/TextInput";
import FormSubmitStatus from "../Components/FormSubmitStatus";
import SubmitButton from "../Components/SubmitButton";
import {
  ErrorsType,
  RegisterType,
} from "../Components/reactHookFormComponents/formTypes";
import { useEffect } from "react";
import { FullIntxTypes } from "../Util/IntersectionHelper";
import { SELECT_ONE_OPTION } from "../Util/FormConstants";

export default function AddCommentFormFields({
  apiResponse,
  errors,
  isFetching,
  register,
  resetForm,
  defaultField,
  size,
  displayIntxTypeBool,
  defaultIntxType,
}: {
  apiResponse?: ApiResponse;
  errors: ErrorsType;
  isFetching: boolean;
  register: RegisterType;
  resetForm: () => void;
  defaultField?: CommentType;
  size?: "small" | "medium";
  displayIntxTypeBool?: boolean;
  defaultIntxType?: string;
}): JSX.Element {
  useEffect(() => {
    if (apiResponse?.isError === false) resetForm();
  }, [apiResponse, resetForm]);

  const maxWidth = size === "small" ? 900 : 1200;
  const rowNumber = size === "small" ? 3 : 6;

  const intxTypeOptions = [SELECT_ONE_OPTION, ...FullIntxTypes];

  return (
    <Stack spacing={2} maxWidth={maxWidth}>
      <TextInput
        errors={errors}
        isRequired
        label="Add your comment here"
        multiline
        name="body"
        register={register}
        rows={rowNumber}
        maxLength={1500}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          width="100%"
        >
          <SelectInput
            defaultValue={defaultField ?? CommentType.Overall}
            errors={errors}
            label="Which page is the comment about?"
            isRequired
            name="commentType"
            options={Object.entries(CommentTypeToLabel)
              .filter(([type]) => Number(type) !== CommentType.Automated)
              .map(([type, label]) => ({ value: type, label }))}
            register={register}
          />
          {displayIntxTypeBool && (
            <SelectInput
              defaultValue={defaultIntxType ?? intxTypeOptions[0]}
              errors={errors}
              label="Which design option is the comment about?"
              isRequired
              name="intersectionType"
              options={intxTypeOptions.map((type) => ({
                value: type,
                label: type,
              }))}
              register={register}
            />
          )}
          <SubmitButton
            isLoading={isFetching}
            sx={{ width: 150, color: "white" }}
          >
            Save
          </SubmitButton>
        </Stack>
      </div>
      <FormSubmitStatus response={apiResponse} />
    </Stack>
  );
}
