import React from "react";
import {
  Alert,
  Box,
  Divider,
  Fab,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";
import {
  DIR_EB,
  DIR_WB,
  DIR_NB,
  DIR_SB,
  DIR_NS,
  DIR_EW,
  DIR_SE,
  DIR_NW,
  DIR_NE,
  DIR_SW,
  DIR_Mapping,
  POS_Mapping,
} from "../vjust-analysis-engine/js/Helper/Helper";
import {
  IntxIconMapper,
  ZonesMapper,
  DisplayPositionMapper,
  RoundaboutPositionMapper,
  BowtiePositionMapper,
} from "../Util/IntersectionHelper";
import { ProjectKeyMap } from "../Analysis/ProjectHelper";
import { Volume } from "../vjust-analysis-engine/js/Intersection/Intersection";
import FullHeightIntxDiagram, { DIAGRAM_TYPES } from "./FullHeightIntxDiagram";
import DiagramToggleButton from "./DiagramToggleButton";
import EditNavBar from "./EditNavBar";
import {
  getZoneHighlight,
  DisplayOneDirectionLane,
  TempFullTurnLaneInput,
  ConflictPointsCard,
  TempRoundaboutTurnLaneInput,
  DisplayRoundaboutOneDirectionLane,
} from "./EditIntersectionDialog";
import { VcResultsCard } from "./VcResultsCard";
import { getZoneLabelForZoneId } from "../vjust-analysis-engine/js/Helper/UnsigVCHelper";
import CommentDialog from "./CommentDialog";
import { isCommentDialogOpenAtom } from "../state/globalState";
import { useAtom } from "jotai";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CloseIcon from "@mui/icons-material/Close";

const inputSectionStyle = { mb: "15px" };
const wideSelectStyle = { minWidth: "225px" };
const rowStyle = { display: "flex", width: "100%" };
const col1Style = {
  width: "110px",
  display: "flex",
  paddingRight: "10px",
};
const col2Style = { flexGrow: 1 };
const dividerRowStyle = { paddingBottom: "10px" };

//----------------Generate Edit Configuration Page----------------------------------------
export default function EditIntersectionDialogDetails({
  handleClose,
  saveChanges,
  intersectionName,
  intersectionList,
  defaultVjustValues,
  projectVolumes,
  projectGlobalInputs,
}) {
  const [intersection, setIntersection] = React.useState(null);
  const [intxEngine, setIntxEngine] = React.useState(null);
  // TODO @elliotcobb remove this unused variable
  // eslint-disable-next-line no-unused-vars
  const [planningLevelCost, setPlanningLevelCost] = React.useState("$");

  const [selectedDemandScenario, setSelectedDemandScenario] = React.useState(0);
  const handleSelectedDemandScenarioChange = (evt) => {
    setSelectedDemandScenario(evt.target.value);
  };

  const [orientationOptions, setOrientationOptions] = React.useState([]);
  const [intersectionOrientation, setIntersectionOrientation] =
    React.useState("");
  const handleIntersectionOrientationChange = (evt) => {
    setIntersectionOrientation(evt.target.value);
  };

  React.useEffect(() => {
    for (let i = 0; i < intersectionList.length; i++) {
      if (intersectionList[i].name === intersectionName) {
        const intxRef = intersectionList[i];
        setIntersection(intxRef);
        // const tempProjVols = projectGlobalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS][0]
        const currVolScenario =
          projectGlobalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS][
            selectedDemandScenario
          ][ProjectKeyMap.ID_GI_SCN_VOLUMES];
        const tempProjVols = {
          eastbound: new Volume(
            currVolScenario.EB.left,
            currVolScenario.EB.thru,
            currVolScenario.EB.right,
            currVolScenario.EB.truckPct
          ),
          westbound: new Volume(
            currVolScenario.WB.left,
            currVolScenario.WB.thru,
            currVolScenario.WB.right,
            currVolScenario.WB.truckPct
          ),
          northbound: new Volume(
            currVolScenario.NB.left,
            currVolScenario.NB.thru,
            currVolScenario.NB.right,
            currVolScenario.NB.truckPct
          ),
          southbound: new Volume(
            currVolScenario.SB.left,
            currVolScenario.SB.thru,
            currVolScenario.SB.right,
            currVolScenario.SB.truckPct
          ),
          scenarioName:
            projectGlobalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS][0][
              ProjectKeyMap.ID_GI_SCN_NAME
            ],
        };
        const inputGlobalParams = {
          LTAF: projectGlobalInputs[ProjectKeyMap.ID_GI_TURN_FACTORS].LTAF,
          RTAF: projectGlobalInputs[ProjectKeyMap.ID_GI_TURN_FACTORS].RTAF,
          UTAF: projectGlobalInputs[ProjectKeyMap.ID_GI_TURN_FACTORS].UTAF,
          TAF: projectGlobalInputs[ProjectKeyMap.ID_GI_TRUCK_PCE],
          CLV_Limit: projectGlobalInputs[ProjectKeyMap.ID_GI_CLV_LIMIT],
          leftTurnFactor:
            projectGlobalInputs[ProjectKeyMap.ID_GI_LEFT_TURN_FACTOR],
          conflict: projectGlobalInputs[ProjectKeyMap.ID_GI_CONFLICT_WEIGHTS],
        };

        const tempIntxEngine = IntxBuilder.createCapacityEngine(
          intxRef.type,
          intxRef.name,
          tempProjVols,
          inputGlobalParams,
          intersectionOrientation
        );
        setPlanningLevelCost(tempIntxEngine.getPlanningLevelCostStr);
        setIntxEngine(tempIntxEngine);
        return;
      }
    }
  }, [
    intersectionList,
    intersectionName,
    projectGlobalInputs,
    projectVolumes,
    selectedDemandScenario,
    intersectionOrientation,
  ]);

  const [intxNewName, setIntxNewName] = React.useState("");
  const [invalidIntxName, setInvalidIntxName] = React.useState(false); // Helper for validation
  const handleIntersectionNameChange = React.useCallback(
    (evt) => {
      if (invalidIntxName) {
        setInvalidIntxName(false);
      }
      setIntxNewName(evt.target.value);
    },
    [invalidIntxName]
  );
  const editNameTextFieldRef = React.useRef(null);
  const [inputsClone, setInputsClone] = React.useState(null);
  const [inputZoneList, setInputZoneList] = React.useState([]);
  const [mappedDirections, setMappedDirections] = React.useState(
    DIR_Mapping.default
  );

  React.useEffect(() => {
    if (intersection === null) {
      setInputsClone(null);
    } else {
      try {
        setIntxNewName(intersection.name);
        const tempIntxClone = JSON.parse(
          JSON.stringify(intersection.laneConfigInputs)
        );
        const isZoneListExist = IntxBuilder.checkIfZoneOptionsExist(
          intersection.type
        );
        setInputZoneList(isZoneListExist ? Object.keys(tempIntxClone) : []);
        if (isZoneListExist) setZone(Object.keys(tempIntxClone)?.[0]);
        setInputsClone(tempIntxClone);
        const inputMajorStrDirection =
          intersection.intersectionOrientation || "";
        setIntersectionOrientation(inputMajorStrDirection);

        const optsOrientation = IntxBuilder.getDefaultOrientationOptions(
          intersection.type
        );
        setOrientationOptions(optsOrientation !== null ? optsOrientation : []);
      } catch (e) {
        alert(e.toString());
      }
    }
  }, [intersection]);

  // Map direction by intersection type
  React.useEffect(() => {
    const mappedDirection = getMapperDirections(
      intersection?.type,
      intersectionOrientation
    );
    if (mappedDirection) {
      setMappedDirections(mappedDirection);
    }
  }, [intersection, intersectionOrientation, orientationOptions]);

  const [zone, setZone] = React.useState(inputZoneList?.[0]);
  const handleZoneChange = (evt) => {
    setZone(evt.target.value);
  };

  const handleInputUpdate = React.useCallback(
    (direction, movement, value) => {
      setInputsClone((prevState) => ({
        ...prevState,
        [zone]: {
          ...prevState[zone],
          [direction]: {
            ...prevState[zone][direction],
            [movement]: value,
          },
        },
      }));
    },
    [zone]
  );

  const handleControlTypeUpdate = (value) => {
    setInputsClone((prevState) => ({
      ...prevState,
      [zone]: {
        ...prevState[zone],
        ControlType: value,
      },
    }));
  };

  const handleRoundaboutUpdate = (direction, movement, value) => {
    setInputsClone((prevState) => ({
      ...prevState,
      [direction]: {
        ...prevState[direction],
        [movement]: value,
      },
    }));
  };

  const handleTWSCStopApproachUpdate = (value) => {
    setInputsClone((prevState) => ({
      ...prevState,
      [zone]: {
        ...IntxBuilder.getDefaultInputs(IntxBuilder.TYPE_TWSC)[zone], // revert back to default to so that stop controlled approaches have one lane per movement
        StopControlDirection: value,
      },
    }));
  };

  const handleMovementsStagesUpdate = React.useCallback(
    (value) => {
      setInputsClone((prevState) => ({
        ...prevState,
        [zone]: {
          ...prevState[zone],
          NumStages: value,
        },
      }));
    },
    [zone]
  );

  const [currentDiagramType, setCurrentDiagramType] = React.useState(
    DIAGRAM_TYPES.DEFAULT
  );

  const [conflictPoints, setConflictPoints] = React.useState(null);

  // Set conflict points value depends on the diagram type and intersection type from defaultVjustValues, and use intersection.getWeightedConflictPointsCard() if the default value is not available
  function setConflictPointsValue(diagramType) {
    let defaultConflictPoints = null;
    switch (diagramType) {
      case DIAGRAM_TYPES.CONFLICT_POINT:
        defaultConflictPoints =
          defaultVjustValues.conflictPoints?.[intersection?.type];

        if (defaultConflictPoints) {
          setConflictPoints(
            getWeightedConflictPointsCardObj(
              defaultConflictPoints,
              projectGlobalInputs[ProjectKeyMap.ID_GI_CONFLICT_WEIGHTS]
            )
          );
        } else if (
          typeof intxEngine.getWeightedConflictPointsCard === "function"
        ) {
          console.log("fail to get default conflict points...");
          setConflictPoints(intxEngine.getWeightedConflictPointsCard());
        }
        break;
      case DIAGRAM_TYPES.THREE_LEG_CONFLICT_POINT:
        defaultConflictPoints =
          defaultVjustValues.conflictPoints?.[intersection?.type + " T"];

        if (defaultConflictPoints) {
          setConflictPoints(
            getWeightedConflictPointsCardObj(
              defaultConflictPoints,
              projectGlobalInputs[ProjectKeyMap.ID_GI_CONFLICT_WEIGHTS]
            )
          );
        } else if (
          typeof intxEngine.get3LegWeightedConflictPointsCard === "function"
        ) {
          console.log("fail to get default conflict points...");
          setConflictPoints(intxEngine.get3LegWeightedConflictPointsCard());
        }
        break;
      default:
        setConflictPoints(null);
    }
  }

  // Handle diagram change action
  const handleDiagramChange = (e, value) => {
    if (value) {
      setConflictPointsValue(value);
      setCurrentDiagramType(value);
    }
  };

  //Handle "Save" button
  const handleSaveClick = React.useCallback(() => {
    const newIntxName = intxNewName.trim();
    const isNewIntxNameUnique = intersectionList.every(
      ({ name }) => name !== newIntxName
    );
    const isNameUnchanged = newIntxName === intersectionName;
    if ((newIntxName && isNewIntxNameUnique) || isNameUnchanged) {
      saveChanges(
        intersectionName,
        newIntxName,
        inputsClone,
        intersectionOrientation
      );
      handleClose();
    } else {
      setInvalidIntxName(true);
      editNameTextFieldRef.current.focus();
    }
  }, [
    intxNewName,
    intersectionName,
    intersectionList,
    saveChanges,
    inputsClone,
    intersectionOrientation,
    handleClose,
  ]);

  const [resultsObj, setResultsObj] = React.useState({});
  React.useEffect(() => {
    if (intxEngine !== null && inputsClone !== null) {
      intxEngine.setLaneConfigInputs(inputsClone);
      intxEngine.computeVCAnalysis();
      const zoneResults = intxEngine.resultsByZone;
      setResultsObj({
        maxVC: intxEngine.maxVC,
        maxCLV: intxEngine.maxCLV,
        zoneResults: zoneResults,
      });
    }
  }, [intxEngine, inputsClone, zone]);

  const isTWSC = intersection?.type === IntxBuilder.TYPE_TWSC; // this intersection is TWSC

  const IntersectionLaneConfigArray = [
    { label: "Eastbound", dir: "EB" },
    { label: "Westbound", dir: "WB" },
    { label: "Northbound", dir: "NB" },
    { label: "Southbound", dir: "SB" },
  ];

  const RoundaboutLaneConfigArray =
    intersectionOrientation === DIR_NS
      ? [
          { label: "Eastbound (Z1)", dir: "SB" },
          { label: "Westbound (Z2)", dir: "NB" },
          { label: "Northbound (Z3)", dir: "EB" },
          { label: "Southbound (Z4)", dir: "WB" },
        ]
      : [
          { label: "Southbound (Z1)", dir: "SB" },
          { label: "Northbound (Z2)", dir: "NB" },
          { label: "Eastbound (Z3)", dir: "EB" },
          { label: "Westbound (Z4)", dir: "WB" },
        ];

  const showMovementStageSelect =
    !!inputsClone?.[zone]?.NumStages &&
    inputsClone?.[zone]?.ControlType !== IntxBuilder.SIGNALIZED;
  const showMinorStreetDirectionSelect = isTWSC && !!inputsClone?.[zone];
  const showControlTypeSelect = !!inputsClone?.[zone]?.ControlType;
  const showZoneSelect = inputZoneList.length > 1;
  const showSectionB =
    showZoneSelect ||
    showControlTypeSelect ||
    showMinorStreetDirectionSelect ||
    showMovementStageSelect;

  const [isCommentDialogOpen, setIsCommentDialogOpen] = useAtom(
    isCommentDialogOpenAtom
  );

  return (
    <>
      <EditNavBar
        disabledSaveButtonTooltipText={
          intxEngine?.formErrors?.length > 0
            ? `Fix form errors in ${getZoneLabelForZoneId(
                intxEngine.formErrors[0]?.zone
              )} to save progress`
            : undefined
        }
        handleClose={handleClose}
        handleSaveClick={handleSaveClick}
        title="Edit Intersection Configuration"
      />

      {intersection !== null && (
        <Grid container spacing={1} sx={{ p: 2, height: "calc(100% - 64px)" }}>
          <Grid item xs={12} sx={{ display: "flex", height: "110px" }}>
            <div
              style={{
                width: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <img
                alt="intx-icon"
                width="90"
                src={IntxIconMapper[intersection.type]}
                style={{ margin: "auto" }}
              />
            </div>
            <div
              style={{
                minWidth: "440px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{ display: "flex", flexGrow: 1, paddingRight: "10px" }}
              >
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    inputRef={editNameTextFieldRef}
                    value={intxNewName}
                    helperText={
                      invalidIntxName
                        ? "Must be unique and not blank"
                        : undefined
                    }
                    onChange={handleIntersectionNameChange}
                    label="Configuration Name"
                    size="small"
                    multiline
                    maxRows={2}
                    inputProps={{ maxLength: "200" }}
                    error={invalidIntxName}
                    fullWidth
                  />
                </div>
              </div>
              <div style={{ display: "flex", flexGrow: 1 }}>
                <div style={{ width: "175px" }}>
                  <b>Configuration Type:</b>
                </div>
                <div style={{ flexGrow: 1 }}>{intersection.type}</div>
              </div>
            </div>
            <div
              style={{
                flexGrow: 1,
                borderLeft: "1px solid lightgrey",
                paddingLeft: "20px",
              }}
            >
              <div style={{ display: "flex", columnGap: "2em" }}>
                <FormControl size="small">
                  <InputLabel id="demand-select-helper-label">
                    Demand Scenario
                  </InputLabel>
                  <Select
                    labelId="demand-select-helper-label"
                    label={"Demand Scenario"}
                    value={selectedDemandScenario}
                    onChange={handleSelectedDemandScenarioChange}
                  >
                    {projectGlobalInputs !== null &&
                      projectGlobalInputs[
                        ProjectKeyMap.ID_GI_VOL_SCENARIOS
                      ].map((scen, scenIdx) => {
                        return (
                          <MenuItem
                            key={`key-dem-scenario-${scenIdx}`}
                            value={scenIdx}
                          >
                            {scen[ProjectKeyMap.ID_GI_SCN_NAME]}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    Select Scenario to View Results
                  </FormHelperText>
                </FormControl>

                {/*Result table*/}
                {resultsObj.maxVC ? (
                  <div
                    style={{
                      display: "flex",
                      alighItems: "center",
                      justifyContent: "center",
                      marginLeft: "10px",
                    }}
                  >
                    <VcResultsCard
                      type={intersection.type}
                      resultsObj={resultsObj}
                    />
                  </div>
                ) : null}
                {/* Show error message when TWSC returns a N/A V/C value*/}
                {intersection.type === IntxBuilder.TYPE_TWSC &&
                  resultsObj?.zoneResults?.Z5?.VC < 0 && (
                    <Alert
                      severity="error"
                      style={{ marginBottom: "10px", maxWidth: "50%" }}
                    >
                      {resultsObj.zoneResults.Z5.error}
                    </Alert>
                  )}
                <DiagramToggleButton
                  currentDiagramType={currentDiagramType}
                  handleDiagramChange={handleDiagramChange}
                  withThreeLegConflictPointDiagram={
                    intersection.type === IntxBuilder.TYPE_SIGNAL
                  }
                />
                {/*ConflictPoints table*/}
                <div>
                  <ConflictPointsCard conflictPoints={conflictPoints} />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ height: "20px" }}>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{ height: "calc(100% - 110px)" }}>
            <div style={{ display: "flex", height: "100%" }}>
              <Grid
                container
                spacing={1}
                sx={{
                  height: "100%",
                  minWidth: "550px",
                  maxWidth: "550px",
                  overflow: "auto",
                }}
              >
                {/*------------------------------------A. Orientation------------------------------------------------*/}
                {orientationOptions.length === 0 ? null : (
                  <Grid item sx={inputSectionStyle} xs={12}>
                    <b style={{ fontSize: "20pt" }}>A. </b>
                    <FormControl size="small" sx={{ mr: "20px" }}>
                      <InputLabel id="major-street-direction-select-label">
                        Orientation
                      </InputLabel>
                      <Select
                        labelId="major-street-direction-select-label"
                        id="major-street-direction-select"
                        value={intersectionOrientation}
                        onChange={handleIntersectionOrientationChange}
                        label="Orientation"
                        disabled={
                          inputsClone === null ||
                          orientationOptions.length === 0
                        }
                        sx={wideSelectStyle}
                      >
                        {orientationOptions.map((opt) => (
                          <MenuItem key={"direction-" + opt} value={opt}>
                            {opt}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {/* FIXME: Need to get helper text for different intersection types */}
                        {intersectionOrientation
                          ? ""
                          : "*Select Option to Edit"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                )}

                {/*------------------------------------B. Zone Selection------------------------------------------------*/}
                {!!showSectionB && (
                  <Grid item sx={{ display: "flex" }} xs={12}>
                    <b style={{ fontSize: "20pt", marginRight: "10px" }}>B. </b>
                    <Box>
                      {showZoneSelect && (
                        <Box sx={inputSectionStyle}>
                          <FormControl size="small">
                            <InputLabel id="zone-select-helper-label">
                              Zone
                            </InputLabel>
                            <Select
                              labelId="zone-select-helper-label"
                              id="zone-select-input"
                              value={zone}
                              onChange={handleZoneChange}
                              label="Zone"
                              disabled={inputsClone === null}
                              sx={wideSelectStyle}
                            >
                              {inputZoneList.length > 0 &&
                                inputZoneList.map((zoneOpt) => (
                                  <MenuItem
                                    key={"zone-select-" + zoneOpt}
                                    value={zoneOpt}
                                  >
                                    {getZoneLabel(zoneOpt)}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                      )}

                      {showControlTypeSelect && (
                        <Box sx={inputSectionStyle}>
                          <FormControl size="small">
                            <InputLabel id="control-type-select-helper-label">
                              Control Type
                            </InputLabel>
                            <Select
                              labelId="control-type-select-helper-label"
                              id="control-type-select-input"
                              value={inputsClone[zone].ControlType}
                              onChange={(event) =>
                                handleControlTypeUpdate(event.target.value)
                              }
                              label="Control Type"
                              sx={wideSelectStyle}
                            >
                              <MenuItem
                                key={"signalized-control-type"}
                                value={IntxBuilder.SIGNALIZED}
                              >
                                Signalized
                              </MenuItem>
                              <MenuItem
                                key={"unsignalized-control-type"}
                                value={IntxBuilder.UNSIGNALIZED}
                              >
                                Unsignalized
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                      {/* For TWSC, set stop controlled directions (minor street directions)*/}
                      {showMinorStreetDirectionSelect && (
                        <Box sx={inputSectionStyle}>
                          <FormControl size="small">
                            <InputLabel id="twsc-stop-control-select-label">
                              Stop Controlled
                            </InputLabel>
                            <Select
                              labelId="twsc-stop-control-select-label"
                              id="twsc-stop-control-select-input"
                              value={inputsClone[zone].StopControlDirection}
                              onChange={(event) =>
                                handleTWSCStopApproachUpdate(event.target.value)
                              }
                              label="Stop Controlled"
                              sx={wideSelectStyle}
                            >
                              <MenuItem value={DIR_EW}>{DIR_EW}</MenuItem>
                              <MenuItem value={DIR_NS}>{DIR_NS}</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      )}

                      {/* For TWSC and MUT (if unsignalized is selected), prompt to choose one-stage vs. two-stage movement */}
                      {showMovementStageSelect && (
                        <Box sx={inputSectionStyle}>
                          <FormControl size="small">
                            <InputLabel id="stages-select-helper-label">
                              Stages
                            </InputLabel>
                            <Select
                              labelId="stages-select-helper-label"
                              id="stages-select-input"
                              value={inputsClone[zone].NumStages}
                              onChange={(event) =>
                                handleMovementsStagesUpdate(event.target.value)
                              }
                              label="Stages"
                              sx={wideSelectStyle}
                            >
                              <MenuItem value={1}>One-Stage Movements</MenuItem>
                              <MenuItem value={2}>Two-Stage Movements</MenuItem>
                            </Select>
                          </FormControl>
                          <FormHelperText>
                            *One or Two-Stage Minor Street Left and Through
                            Movements
                          </FormHelperText>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                )}

                {/*------------------------------------C. Configure Lanes by Direction------------------------------------------------*/}
                <Grid
                  item
                  sx={{ display: "flex", alignItems: "center" }}
                  xs={12}
                >
                  <b style={{ fontSize: "20pt" }}>C. </b>
                  <Typography
                    sx={{ mb: "10px", ml: "10px", mt: "15px" }}
                    variant="h6"
                  >
                    Lane Configuration
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    height: "calc(100% - 120px)",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%", paddingRight: "10px" }}>
                    {/* Normal Intersection Lane Configuration Inputs */}
                    {!!inputsClone?.[zone] &&
                      IntersectionLaneConfigArray.map(({ label, dir }) => {
                        const errors = intxEngine?.formErrors?.filter(
                          (error) =>
                            error.zone === zone &&
                            error.direction === mappedDirections[dir]
                        );
                        return (
                          inputsClone[zone]?.[mappedDirections[dir]] && (
                            <div key={"normal-intx-lane-inputs-" + dir}>
                              <div style={rowStyle}>
                                <div style={col1Style}>
                                  <b>{label}</b>
                                </div>
                                <div style={col2Style}>
                                  <TempFullTurnLaneInput
                                    errors={errors}
                                    laneConfig={inputsClone}
                                    zone={zone}
                                    direction={mappedDirections[dir]}
                                    updateInput={(movement, value) =>
                                      handleInputUpdate(
                                        mappedDirections[dir],
                                        movement,
                                        value
                                      )
                                    }
                                    intxType={intersection?.type}
                                  />
                                  <TempRoundaboutTurnLaneInput
                                    intxType={intersection?.type}
                                    laneConfig={
                                      inputsClone[zone][mappedDirections[dir]]
                                    }
                                    updateInput={(movement, value) =>
                                      handleInputUpdate(
                                        mappedDirections[dir],
                                        movement,
                                        value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div style={dividerRowStyle}>
                                <Divider variant="middle" />
                              </div>
                            </div>
                          )
                        );
                      })}

                    {/* Roundabout Lane Configuration Inputs */}
                    {[
                      IntxBuilder.TYPE_ROUNDABOUT,
                      IntxBuilder.TYPE_SINGLE_RBT,
                    ].includes(intersection?.type) &&
                      RoundaboutLaneConfigArray.map(({ label, dir }) => {
                        return (
                          !!inputsClone?.[mappedDirections[dir]] && (
                            <div key={"roundabout-intx-lane-inputs-" + dir}>
                              <div style={rowStyle}>
                                <div style={col1Style}>
                                  <b>{label}</b>
                                </div>
                                <div style={col2Style}>
                                  <TempRoundaboutTurnLaneInput
                                    intxType={intersection?.type}
                                    laneConfig={
                                      inputsClone[mappedDirections[dir]]
                                    }
                                    updateInput={(movement, value) =>
                                      handleRoundaboutUpdate(
                                        mappedDirections[dir],
                                        movement,
                                        value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div style={dividerRowStyle}>
                                <Divider variant="middle" />
                              </div>
                            </div>
                          )
                        );
                      })}

                    {/* Mini Roundabout - not Lane Configuration needed */}
                    {[
                      IntxBuilder.TYPE_MINI_RBT_50,
                      IntxBuilder.TYPE_MINI_RBT_75,
                    ].includes(intersection?.type) && (
                      <Typography>No further information needed.</Typography>
                    )}

                    {/*Z3/Z4 Roundabout Lane Configuration for Bowtie*/}
                    {!!inputsClone?.[zone]?.numEntryLanes && (
                      <>
                        <div style={rowStyle}>
                          <div style={col1Style}>
                            <b></b>
                          </div>
                          <div style={col2Style}>
                            <TempRoundaboutTurnLaneInput
                              intxType={intersection?.type}
                              laneConfig={inputsClone[zone]}
                              updateInput={(movement, value) =>
                                handleRoundaboutUpdate(zone, movement, value)
                              }
                            />
                          </div>
                        </div>
                        <div style={dividerRowStyle}>
                          <Divider variant="middle" />
                        </div>
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>

              {/*------------------------------------Intersection Image and View----------------------------------*/}
              <div style={{ width: "calc(100% - 550px)", height: "100%" }}>
                <Paper
                  variant="outlined"
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <FullHeightIntxDiagram
                    diagramType={currentDiagramType}
                    intersectionType={intersection.type}
                    intxOrientation={intersectionOrientation}
                  />
                  {/*Show Zone Frame & highlight zone & show conflict diagram*/}
                  {ZonesMapper[intersection.type] &&
                    getZoneHighlight(ZonesMapper[intersection.type][zone])}

                  {/*Show Arrows and number of lanes*/}
                  {inputsClone !== null && inputsClone[zone]
                    ? ["EB", "WB", "NB", "SB"].map((dir) => {
                        return (
                          inputsClone[zone]?.[mappedDirections[dir]] && (
                            <DisplayOneDirectionLane
                              inputsLane={inputsClone}
                              zone={zone}
                              direction={mappedDirections[dir]}
                              position={DisplayPositionMapper[dir]}
                              zoneBound={ZonesMapper[intersection.type]?.[zone]}
                              orientation={intersectionOrientation}
                              intersectionType={intersection.type}
                              key={"normal-intx-zone-display-" + dir}
                            />
                          )
                        );
                      })
                    : null}

                  {/* Show arrows and lane numbers for Roundabout */}
                  {inputZoneList.length === 0 && inputsClone !== null
                    ? ["SB", "NB", "EB", "WB"].map((dir) => {
                        return (
                          inputsClone[mappedDirections[dir]] && (
                            <DisplayRoundaboutOneDirectionLane
                              inputsLane={inputsClone}
                              direction={mappedDirections[dir]}
                              position={RoundaboutPositionMapper[dir]}
                              key={"roundabout-intx-zone-display-" + dir}
                            />
                          )
                        );
                      })
                    : null}

                  {/* Show arrows and lane numbers for Double Roundabout */}
                  {inputsClone !== null &&
                  inputsClone[zone] &&
                  intersection.type === IntxBuilder.TYPE_DOUBLE_RBT
                    ? ["SB", "NB", "EB", "WB"].map((dir) => {
                        const mappedPosition =
                          intersectionOrientation === DIR_NS
                            ? POS_Mapping[DIR_SE][dir]
                            : dir;
                        return (
                          inputsClone[zone][mappedDirections[dir]] && (
                            <DisplayRoundaboutOneDirectionLane
                              inputsLane={inputsClone[zone]}
                              direction={mappedDirections[dir]}
                              position={
                                RoundaboutPositionMapper[mappedPosition]
                              }
                              zoneBound={ZonesMapper[intersection.type]?.[zone]}
                              key={"double-roundabout-intx-zone-display-" + dir}
                            />
                          )
                        );
                      })
                    : null}

                  {/* Show arrows and lane numbers for Bowtie Roundabout  */}
                  {inputsClone !== null &&
                    inputsClone[zone] &&
                    inputsClone[zone].numEntryLanes && (
                      <DisplayRoundaboutOneDirectionLane
                        inputsLane={inputsClone}
                        direction={zone}
                        position={BowtiePositionMapper[zone]}
                      />
                    )}
                </Paper>
              </div>
            </div>
          </Grid>
        </Grid>
      )}

      {!!isCommentDialogOpen && (
        <CommentDialog stepIndex={2} defaultIntxType={intersection.type} />
      )}
      <Fab
        size="medium"
        sx={{ position: "fixed", right: "30px", bottom: "70px" }}
        onClick={() => setIsCommentDialogOpen(!isCommentDialogOpen)}
      >
        {isCommentDialogOpen ? <CloseIcon /> : <AddCommentIcon />}
      </Fab>
    </>
  );
}

function getZoneLabel(input) {
  let output = "";
  const zones = input.match(/[A-Z]\d+/g); // Extract zones (letters followed by numbers) using regular expression

  if (zones) {
    output = zones
      .map((zone) => {
        const number = zone.match(/\d+/)[0]; // Extract the number from the zone
        return `Zone ${number}`; // Format the zone as "Zone X"
      })
      .join(" & "); // Join the formatted zones using the "&" symbol

    return output;
  }

  return input;
}

function getWeightedConflictPointsCardObj(conflictPoints, conflictWeights) {
  return {
    Crossing: {
      Count: conflictPoints.crossing,
      Weight: conflictWeights.wCrossing,
    },
    Merging: {
      Count: conflictPoints.merging,
      Weight: conflictWeights.wMerging,
    },
    Diverging: {
      Count: conflictPoints.diverging,
      Weight: conflictWeights.wDiverging,
    },
    CP:
      conflictPoints.crossing * conflictWeights.wCrossing +
      conflictPoints.merging * conflictWeights.wMerging +
      conflictPoints.diverging * conflictWeights.wDiverging,
  };
}

export function getMapperDirections(intxType, intxOrientation = DIR_EW) {
  if (!intxType) return null;

  switch (intxType) {
    // IntxBuilder.TYPE_SIGNAL none

    case IntxBuilder.TYPE_BOWTIE:
      return intxOrientation === DIR_NS
        ? DIR_Mapping[DIR_EW]
        : DIR_Mapping[DIR_NS];
    case IntxBuilder.TYPE_SPLIT_INTX:
    case IntxBuilder.TYPE_THRUCUT:
      return intxOrientation === DIR_NS
        ? DIR_Mapping[DIR_NW]
        : DIR_Mapping[DIR_EW];

    //IntxBuilder.TYPE_CTO none

    case IntxBuilder.TYPE_CGT:
      return intxOrientation === DIR_EB
        ? DIR_Mapping.minorStem[DIR_EB]
        : intxOrientation === DIR_WB
        ? DIR_Mapping.minorStem[DIR_WB]
        : intxOrientation === DIR_SB
        ? DIR_Mapping.minorStem[DIR_SB]
        : DIR_Mapping.minorStem[DIR_NB];

    case IntxBuilder.TYPE_ECHELON:
      return intxOrientation === DIR_WB
        ? DIR_Mapping[DIR_NW]
        : DIR_Mapping[DIR_EW];

    // IntxBuilder.TYPE_FDLT none

    case IntxBuilder.TYPE_MUT:
    case IntxBuilder.TYPE_PDLT:
    case IntxBuilder.TYPE_PMUT:
    case IntxBuilder.TYPE_RCUT:
      return intxOrientation === DIR_NS
        ? DIR_Mapping[DIR_NS]
        : DIR_Mapping[DIR_EW];

    case IntxBuilder.TYPE_QR_NW:
      return DIR_Mapping[DIR_NW];
    case IntxBuilder.TYPE_QR_NE:
      return DIR_Mapping[DIR_EW];
    case IntxBuilder.TYPE_QR_SW:
      return DIR_Mapping[DIR_SW];
    case IntxBuilder.TYPE_QR_SE:
      return DIR_Mapping[DIR_NS];

    case IntxBuilder.TYPE_SINGLELOOP:
      switch (intxOrientation) {
        case DIR_SE:
          return DIR_Mapping[DIR_NW];
        case DIR_NE:
          return DIR_Mapping[DIR_SW];
        case DIR_NW:
          return DIR_Mapping[DIR_NS];
        default:
          return DIR_Mapping[DIR_EW];
      }

    // At-Grade Unsignalized None

    // Interchange
    case IntxBuilder.TYPE_TRAD_DIAMOND:
    case IntxBuilder.TYPE_DLTI:
    case IntxBuilder.TYPE_DDI:
    case IntxBuilder.TYPE_DOUBLE_RBT:
    case IntxBuilder.TYPE_MUD:
    case IntxBuilder.TYPE_PARTIAL_CLOVER:
    case IntxBuilder.TYPE_SINGLEPOINT:
    case IntxBuilder.TYPE_SINGLE_RBT:
      return intxOrientation === DIR_NS
        ? DIR_Mapping.interchange_NS
        : DIR_Mapping.interchange_EW;

    // ContraFlow
    case IntxBuilder.TYPE_CONTRAFLOW_LEFT:
      return intxOrientation === DIR_NS
        ? DIR_Mapping.contraflowLeft_NS
        : DIR_Mapping.interchange_EW;

    default:
      return null;
  }
}
