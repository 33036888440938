import { useEffect } from "react";
import { Alert, Tooltip, Typography } from "@mui/material";
import { useAtomValue } from "jotai";

import { currentProjectKeyAtom } from "../state/globalState";
import { useProjects } from "../hooks/useProjects";
import { sessionKeys } from "./ProjectHelper";

export default function UnsavedChangesMessage() {
  const { fetchProject, projectDetails } = useProjects();
  const project = sessionStorage.getItem(sessionKeys.KEY_PROJECT);
  const currentProjectKey = useAtomValue(currentProjectKeyAtom);

  useEffect(() => {
    if (currentProjectKey && !projectDetails[currentProjectKey]) {
      fetchProject(currentProjectKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasUnsavedChanges =
    !!currentProjectKey && projectDetails[currentProjectKey]?.blob !== project;

  return hasUnsavedChanges ? (
    <Tooltip title="This project has local changes that don't exist on the VJuST web server">
      <Alert sx={{ backgroundColor: "white", padding: 0 }} severity="warning">
        Warning: You have unsaved changes, please save a new version online.
      </Alert>
    </Tooltip>
  ) : (
    <></>
  );
}

export function NewAnalysisSaveMessage() {
  const currentProjectKey = useAtomValue(currentProjectKeyAtom);
  const isNewAnalysis = currentProjectKey === undefined;

  return isNewAnalysis ? (
    <Alert sx={{ backgroundColor: "white", padding: 0 }} severity="warning">
      Warning: Unsaved project, please save online or export the analysis
      project.
    </Alert>
  ) : null;
}
