import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import NavButtonBar from "./NavButtonBar";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnalysisTable from "../Components/AnalysisTableV2";
import { AddGroupDialog } from "../Components/AddIntersectionDialog";
import EditIntersectionDialog from "../Components/EditIntersectionDialog";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { Link } from "react-router-dom";
import { ROUTES } from "../Util/RouteConstants";

const STEP_INDEX = 2;

export default function AlternativeConfigurationPane(props) {
  React.useEffect(() => {
    props.updateActiveStep(STEP_INDEX);
  });

  // Handle intersection configuration
  let storedIntersectionsList = props.projectAlternatives;
  if (Array.isArray(storedIntersectionsList) === false) {
    storedIntersectionsList = [];
  }
  const [intersectionsList, setIntersectionsList] = React.useState(
    storedIntersectionsList
  );

  const [editingIntersection, setEditingIntersection] = React.useState("");

  const [numOptionsAdded, setNumOptionsAdded] = React.useState(0);
  const [postAddDialogVisible, setPostAddDialogVisible] = React.useState(false);
  const handlePostAddDialogClose = () => {
    setPostAddDialogVisible(false);
  };

  // Handle intersection add
  const addIntersectionGroupToAnalysis = (intxToBeAdded) => {
    setIntersectionsList((oldArray) => oldArray.concat(intxToBeAdded));
    setNumOptionsAdded(intxToBeAdded.length);
    setPostAddDialogVisible(true);
  };

  // Handle intersection edit dialog
  const [editIntersectionDialogVisible, setEditIntersectionDialogVisible] =
    React.useState(false);
  const closeEditIntxDialog = () => {
    setEditingIntersection("");
    setEditIntersectionDialogVisible(false);
  };
  React.useEffect(() => {
    if (editingIntersection !== "") {
      setEditIntersectionDialogVisible(true);
    }
  }, [editingIntersection]);

  const updateSavedProjectInfo = React.useCallback(() => {
    props.assignAlternativesToProject(intersectionsList);
  }, [intersectionsList, props]);

  // Real-time save intersection list to project
  React.useEffect(() => {
    updateSavedProjectInfo();
  }, [intersectionsList, updateSavedProjectInfo]);

  // Handle intersection inputs update
  const updateIntersectionInputs = React.useCallback(
    (
      oldIntersectionName,
      newIntersectionName,
      newIntersectionInputs,
      intersectionOrientation
    ) => {
      let updatedIntxList = intersectionsList.map((intx) =>
        intx.name !== oldIntersectionName
          ? intx
          : {
              ...intx,
              name:
                newIntersectionName === null
                  ? oldIntersectionName
                  : newIntersectionName,
              laneConfigInputs: newIntersectionInputs,
              intersectionOrientation: intersectionOrientation,
            }
      );
      setIntersectionsList(updatedIntxList);

      // try to save to project
      updateSavedProjectInfo();
    },
    [intersectionsList, updateSavedProjectInfo]
  );

  // Handle intersection delete & clear
  const handleDeleteIntersection = (intxNames) => {
    const tempMap = intxNames.map((item) => item.name);
    setIntersectionsList(
      intersectionsList.filter((item) => tempMap.indexOf(item.name) < 0)
    );
  };

  const [clearAllDesignsDialogVisible, setClearAllDesignsDialogVisible] =
    React.useState(false);

  const handleClearAllDesignsDialogClose = () => {
    setClearAllDesignsDialogVisible(false);
  };

  const handleClearAllIntersections = () => {
    setClearAllDesignsDialogVisible(true);
  };

  const proceedClearAllDesigns = () => {
    setIntersectionsList([]);
    setClearAllDesignsDialogVisible(false);
  };

  const [groupAddDialogVisible, setGroupAddDialogVisible] =
    React.useState(false);
  const showGroupSelectDialog = () => {
    setGroupAddDialogVisible(true);
  };
  const hideGroupSelectDialog = () => {
    setGroupAddDialogVisible(false);
  };

  const handleLaunchExportFromStep = React.useCallback(() => {
    updateSavedProjectInfo();
    props.launchExportDialog();
  }, [props, updateSavedProjectInfo]);

  // -----------------------DEBUG------------------------
  // React.useEffect(() => {
  //   console.log("intx list: ", intersectionsList);
  // }, [intersectionsList]);

  return (
    <div
      style={{
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "20px",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          height: "calc(100% - 50px)",
          overflow: "auto",
          paddingBottom: "20px",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">Design Option Selection</Typography>
          </Grid>
          <Grid item xs={12} sx={{ height: "20px" }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ height: "calc(100% - 50px)" }}>
          <Grid item md={12} lg={4} sx={{ height: "100%" }}>
            <Card
              variant="outlined"
              sx={{
                height: "100%",
                position: "relative",
                overflow: "auto",
              }}
            >
              <Grid
                container
                spacing={1}
                sx={{
                  p: 1,
                  height: "100%",
                  minHeight: "fit-content",
                }}
              >
                <Grid item xs={12} align="center">
                  <Button
                    variant="contained"
                    onClick={showGroupSelectDialog}
                    startIcon={
                      <FontAwesomeIcon size={"xs"} icon={"fa-solid fa-plus"} />
                    }
                  >
                    Add Design Alternative(s)
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ height: "10px" }}>
                  <Divider variant="middle" />
                </Grid>
                <Grid item xs={12} sx={{ height: "calc(100% - 70px)" }}>
                  <div style={{ padding: "20px", display: "flex" }}>
                    <FontAwesomeIcon
                      icon={"fa-solid fa-info-circle"}
                      size="xl"
                      style={{ paddingRight: "5px" }}
                    />
                    <Typography variant="body2" component={"div"}>
                      <em>
                        Select design alternatives to include in the analysis
                        using the above button. Multiple design variations of
                        the same intersection type can be analyzed, allowing for
                        simultaneous analysis of varying
                        <ul>
                          <li>turn lane configurations,</li>
                          <li>shared movements,</li>
                          <li>channelized movements, and</li>
                          <li>other intersection specific options.</li>
                        </ul>
                        Following the selection and configuration of design
                        alternatives, the user will be asked to provide
                        justification for any design options excluded from the
                        analysis.
                      </em>
                    </Typography>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      endIcon={
                        <FontAwesomeIcon
                          style={{ fontSize: "11pt" }}
                          icon={"fa-arrow-up-right-from-square"}
                        />
                      }
                      component={Link}
                      to={ROUTES.DESIGN_CONSIDERATIONS.route}
                      target="_blank"
                    >
                      View Design Considerations
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item md={12} lg={8} sx={{ height: "100%" }}>
            <Card variant="outlined" sx={{ width: "100%", height: "100%" }}>
              <AnalysisTable
                alternativesList={intersectionsList}
                handleDeleteIntersection={handleDeleteIntersection}
                editIntersectionConfig={setEditingIntersection}
                clearAllIntersections={handleClearAllIntersections}
                // editIntersectionName={editIntersectionName}
              />
            </Card>
          </Grid>
        </Grid>
      </div>
      <NavButtonBar
        stepIndex={STEP_INDEX}
        handleNext={updateSavedProjectInfo}
        handleBack={updateSavedProjectInfo}
        launchExportDialog={handleLaunchExportFromStep}
        disableNext={intersectionsList.length === 0}
        nextButtonDisabledTooltip={
          "Add at least one intersection to continue to the next step of the analysis."
        }
      />
      <AddGroupDialog
        open={groupAddDialogVisible}
        handleClose={hideGroupSelectDialog}
        addIntersectionGroupToAnalysis={addIntersectionGroupToAnalysis}
        currentIntersectionsList={intersectionsList}
      />

      <EditIntersectionDialog
        open={editIntersectionDialogVisible}
        handleClose={closeEditIntxDialog}
        intersectionName={editingIntersection}
        intersectionList={intersectionsList}
        defaultVjustValues={props.defaultVjustValues}
        saveChanges={updateIntersectionInputs}
        projectGlobalInputs={props.projectGlobalInputs}
      />
      <Dialog
        open={clearAllDesignsDialogVisible}
        // onClose={handleClearAllDesignsDialogClose}
        aria-labelledby="clear-all-intx-dialog-title"
        aria-describedby="clear-all-intx-dialog-description"
      >
        <DialogTitle id="clear-all-intx-dialog-title">
          {"Clear All Designs Alternatives from Analysis?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="clear-all-intx-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClearAllDesignsDialogClose} autoFocus>
            Cancel
          </Button>
          <Button onClick={proceedClearAllDesigns}>Proceed</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={postAddDialogVisible}
        onClose={handlePostAddDialogClose}
        aria-labelledby="add-option-confirm-dialog-title"
        aria-describedby="add-option-confirm-dialog-description"
      >
        <DialogTitle id="add-option-confirm-dialog-title">
          Design Options Added
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="add-option-confirm-description">
            Added <b>{numOptionsAdded}</b> new design option configurations to
            the project analysis.
            <br />
            <br />
            Please click the Edit icon for each to configure the number of lanes
            for each before proceeding with the analysis.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePostAddDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlternativeConfigurationPane.propTypes = {
  projectAlternatives: PropTypes.array.isRequired,
  assignAlternativesToProject: PropTypes.func.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
  launchExportDialog: PropTypes.func.isRequired,
};
