import { useAtom } from "jotai";
import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

import FormComponent from "./reactHookFormComponents/FormComponent";
import TextInput from "./reactHookFormComponents/TextInput";
import CheckboxInput from "./reactHookFormComponents/CheckboxInput";
import CloseButton from "./CloseButton";
import { ProjectKeyMap, sessionKeys } from "../Analysis/ProjectHelper";
import { useProjects } from "../hooks/useProjects";
import { ApiResponse, CommentType, UserIdName } from "../api/dataTypes";
import FormSubmitStatus from "./FormSubmitStatus";
import { currentProjectKeyAtom } from "../state/globalState";
import { useComments } from "../hooks/useComments";
import { useProjectReviewers } from "../hooks/useProjectReviewers";
import ChooseReviewersAutocomplete from "./ChooseReviewersAutocomplete";

export default function SubmitOnlineDialog({
  handleClose,
  setIsSnackbarOpen,
  setSnackbarMessage,
}: {
  handleClose: () => void;
  setIsSnackbarOpen: (b: boolean) => void;
  setSnackbarMessage: (s: string) => void;
}): JSX.Element {
  const [, setCurrentProjectKey] = useAtom<string | undefined>(
    currentProjectKeyAtom
  );
  const [saveResponse, setSaveResponse] = useState<ApiResponse | undefined>();
  const [addReviewerResponses, setAddReviewerResponses] = useState<
    ApiResponse[]
  >([]);
  const [addCommentResponse, setAddCommentResponse] = useState<ApiResponse>();
  const { addCommentToProject } = useComments();
  const { addReviewerToProject } = useProjectReviewers();
  const { createProject, fetchProject } = useProjects();
  const handleSaveSuccess = (isSubmit: boolean) => {
    setIsSnackbarOpen(true);
    setSnackbarMessage(
      isSubmit
        ? "Project saved and submitted for review successfully!"
        : "Project saved successfully!"
    );
    handleClose();
  };

  const projectDataJson =
    sessionStorage.getItem(sessionKeys.KEY_PROJECT) ?? "{}";
  const projectData = JSON.parse(projectDataJson);
  const timeStamp = new Date().toLocaleString("en-US", { timeZone: "EST" });
  const projectName: string | undefined =
    projectData?.[ProjectKeyMap.ID_PROJECT_INFO]?.[
      ProjectKeyMap.ID_PI_PROJECT_NAME
    ] +
    " " +
    timeStamp;

  return (
    <Dialog open onClose={handleClose}>
      <CloseButton onClick={handleClose} />
      <DialogTitle>Save Project Online</DialogTitle>
      <Box minWidth={600} paddingX={3} paddingBottom={3}>
        <Typography sx={{ mb: 3 }} variant="body2">
          Saves your analysis as a new project on the VJuST web server.
        </Typography>
        <FormComponent
          defaultValues={{
            comment: "",
            reviewers: [],
            submitForReview: false,
            title: projectName,
          }}
          onFormSubmit={async (values) => {
            const response: ApiResponse = await createProject(
              values.title,
              values.submitForReview ? 1 : 0,
              projectDataJson
            );
            setSaveResponse(response);
            if (!response.isError) {
              // try all reviewer adds
              // if any are error, add to list of errors
              const projectKey = response.data?.key;
              setCurrentProjectKey(projectKey);
              fetchProject(projectKey);
              if (values.submitForReview) {
                const reviewerPromises = values.reviewers.map(
                  async ({ id }: UserIdName) => {
                    return await addReviewerToProject(id, projectKey);
                  }
                );
                // Await all the promises
                const reviewerResponses = await Promise.all(reviewerPromises);

                if (values.comment) {
                  const commentResponse = await addCommentToProject(
                    values.comment,
                    CommentType.Overall,
                    projectKey
                  );
                  setAddCommentResponse(commentResponse);
                }

                // Update the state with the responses
                setAddReviewerResponses(reviewerResponses);
                if (reviewerResponses.every(({ isError }) => !isError)) {
                  handleSaveSuccess(true);
                }
              } else {
                handleSaveSuccess(false);
              }
            }
          }}
        >
          {({ control, defaultValues, errors, formData, register }) => {
            return (
              <Stack spacing={2}>
                <TextInput
                  defaultValue={defaultValues.title}
                  errors={errors}
                  label="Project Name"
                  name="title"
                  register={register}
                  isRequired
                />
                <Box>
                  <CheckboxInput
                    defaultChecked={defaultValues.submitForReview}
                    label="Submit for review?"
                    name="submitForReview"
                    register={register}
                  />
                </Box>
                {formData.submitForReview && (
                  <>
                    <ChooseReviewersAutocomplete
                      control={control}
                      errors={errors}
                    />
                    <TextInput
                      defaultValue={defaultValues.comment}
                      errors={errors}
                      name="comment"
                      multiline
                      placeholder="Include comment with review (optional)"
                      register={register}
                      isRequired={false}
                    />
                  </>
                )}
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  minWidth={370}
                  spacing={2}
                >
                  <Button color="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ color: "white" }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Stack>
                <FormSubmitStatus
                  response={saveResponse}
                  title="Save project"
                />
                {addReviewerResponses
                  .filter(({ isError }) => isError)
                  .map((response) => {
                    return (
                      <FormSubmitStatus
                        response={response}
                        title="Add project reviewers"
                      />
                    );
                  })}
                <FormSubmitStatus
                  response={addCommentResponse}
                  title="Add comment"
                />
              </Stack>
            );
          }}
        </FormComponent>
      </Box>
    </Dialog>
  );
}
