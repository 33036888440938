import {
  faArrowTurnDownLeft,
  faArrowUp,
  faQuestionCircle,
  faTruck,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import TooltipIcon from "./TooltipIcon";
import { Fragment, useEffect, useState } from "react";
import { TruckPercentInput, VolumeInput } from "./CustomInputs";
import CopyPasteVolumesDialog from "./CopyPasteVolumesDialog";

type Volumes = {
  label: string;
  left: number | string;
  thru: number | string;
  right: number | string;
  truckPct: number | string;
};

export default function VolumesGrid({
  discardUnsavedChanges,
  handleInvalidInputs,
  handleNameChange,
  handleStartTimeChange,
  handleVolumeChange,
  invalidScenName,
  preventSaveInvalid,
  scenarioNameInputRef,
  scenName,
  startTime,
  unsavedChanges,
  updateSavedVolumeScenario,
  volumes,
}: {
  discardUnsavedChanges: any;
  handleInvalidInputs: any;
  handleNameChange: any;
  handleStartTimeChange: any;
  handleVolumeChange: any;
  invalidScenName: any;
  preventSaveInvalid: any;
  scenarioNameInputRef: any;
  scenName: any;
  startTime: any;
  unsavedChanges: any;
  updateSavedVolumeScenario: any;
  volumes: {
    EB: Volumes;
    WB: Volumes;
    NB: Volumes;
    SB: Volumes;
  } | null;
}): React.ReactNode {
  const [isCopyPasteDialogOpen, setIsCopyPasteDialogOpen] =
    useState<boolean>(false);
  const [pasteError, setPasteError] = useState<string | undefined>(undefined);
  const theme = useTheme();

  useEffect(() => {
    const handlePaste = function (event: any) {
      console.log("pasting something");
      // Get clipboard data
      const clipboardData = event.clipboardData;
      const pastedData = clipboardData?.getData?.("text") ?? "";

      // Split pasted data by new lines for rows and tabs for columns
      const rows = pastedData
        .trim()
        .split("\n")
        .map((row: string) => row.split("\t"));

      // just return if user is trying to paste something that's not a grid of data
      if (rows.length < 2 || !pastedData.includes("\t")) {
        return;
      }
      // show an alert if the pasted data is a grid, but it's not a 4x4 grid
      if (
        rows.length !== 4 ||
        rows.some((row: (number | string)[]) => row.length !== 4)
      ) {
        setPasteError(
          `Failed to paste 4x4 grid of volumes. Grid should have 4 rows and 4 columns, but your grid has ${rows.length} rows and ${rows[0].length} columns.`
        );
        event.preventDefault();
        return;
      }

      // clear any previous paste error
      setPasteError(undefined);

      const inputTypes = ["left", "thru", "right", "truckPct"];
      const directions = ["EB", "WB", "NB", "SB"];

      // Iterate through the rows and columns to populate the 4x4 grid
      directions.forEach((direction, rowIndex) => {
        inputTypes.forEach((inputType, colIndex) => {
          const cellValue =
            rows[rowIndex] && rows[rowIndex][colIndex]
              ? rows[rowIndex][colIndex].trim()
              : "";
          if (cellValue) {
            handleVolumeChange(direction, inputType, cellValue);
          }
        });
      });

      // Prevent the default paste behavior
      event.preventDefault();
    };

    document.addEventListener("paste", handlePaste);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      variant="outlined"
      sx={{
        height: "100%",
        padding: "10px",
        minWidth: "650px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid container spacing={1} sx={{ minWidth: "650px", maxWidth: "800px" }}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item lg={4} xl={5}>
              <TextField
                inputRef={scenarioNameInputRef}
                value={scenName}
                onChange={handleNameChange}
                label="Name"
                size="small"
                inputProps={{ maxLength: "80" }}
                error={invalidScenName}
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title={
                        <span style={{ fontSize: "11pt" }}>
                          Scenario name cannot be blank or the same as another
                          existing volume scenario. Maximum 80 characters.
                        </span>
                      }
                      arrow
                    >
                      <span style={{ marginLeft: "2px" }}>
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          style={{
                            color: theme.palette.grey[400],
                          }}
                        />
                      </span>
                    </Tooltip>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item lg={4} xl={3}>
              <TimePicker
                label="Start Time"
                minutesStep={5}
                onChange={(newValue: any) => handleStartTimeChange(newValue)}
                slotProps={{
                  textField: { size: "small", variant: "outlined" },
                }}
                value={startTime}
              />
            </Grid>
            <Grid item lg={4} xl={4}>
              <Tooltip title="Save traffic volumes for this scenario.">
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ float: "right", color: "white" }}
                  onClick={updateSavedVolumeScenario}
                  disabled={!unsavedChanges || preventSaveInvalid}
                >
                  Save Volumes
                </Button>
              </Tooltip>
              <Tooltip title="Reset traffic volumes.">
                <Button
                  color="warning"
                  sx={{ float: "right" }}
                  disabled={!unsavedChanges}
                  onClick={discardUnsavedChanges}
                >
                  Reset
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1" align="center">
            <b style={{ textDecoration: "underline" }}>Movement</b>
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowTurnDownLeft}
                  flip="vertical"
                  style={{ fontSize: "24pt" }}
                />
              </div>
              <Typography
                variant="body2"
                align={"center"}
                sx={{ width: "100%" }}
              >
                U-Turn/Left
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowUp}
                  style={{ fontSize: "24pt" }}
                />
              </div>
              <Typography
                variant="body2"
                align={"center"}
                sx={{ width: "100%" }}
              >
                Through
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowTurnDownLeft}
                  flip="both"
                  style={{ fontSize: "24pt" }}
                />
              </div>
              <Typography
                variant="body2"
                align={"center"}
                sx={{ width: "100%" }}
              >
                Right Turn
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faTruck}
                  style={{ fontSize: "24pt", margin: "auto" }}
                />
              </div>
              <Typography
                variant="body2"
                align={"center"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Truck Percent
                <TooltipIcon
                  title={
                    "Truck percentage values are used to adjust turning movement volumes to equivalent passenger cars."
                  }
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {volumes !== null &&
          Object.entries(volumes).map(([key, value]) => (
            <Fragment key={"vol-row-" + key}>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1" align="center">
                  <b>{value.label}</b>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <VolumeInput
                      label=""
                      value={value.left}
                      handleChange={(evt: any) => {
                        handleVolumeChange(key, "left", evt.target.value);
                        handleInvalidInputs(
                          key,
                          0,
                          parseInt(evt.target.value) < 0 ||
                            isNaN(parseInt(evt.target.value))
                        );
                      }}
                      name={`vol-input-${key}`}
                      id={`vol-input-${key}-left`}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <VolumeInput
                      label=""
                      value={value.thru}
                      handleChange={(evt: any) => {
                        handleVolumeChange(key, "thru", evt.target.value);
                        handleInvalidInputs(
                          key,
                          1,
                          parseInt(evt.target.value) < 0 ||
                            isNaN(parseInt(evt.target.value))
                        );
                      }}
                      name={`vol-input-${key}`}
                      id={`vol-input-${key}-thru`}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <VolumeInput
                      label=""
                      value={value.right}
                      handleChange={(evt: any) => {
                        handleVolumeChange(key, "right", evt.target.value);
                        handleInvalidInputs(
                          key,
                          2,
                          parseInt(evt.target.value) < 0 ||
                            isNaN(parseInt(evt.target.value))
                        );
                      }}
                      name={`vol-input-${key}`}
                      id={`vol-input-${key}-right`}
                    />
                  </Grid>
                  <Grid item xs={3} sx={{ display: "flex" }}>
                    <Divider
                      orientation="vertical"
                      style={{ position: "relative", left: "-4px" }}
                    />
                    <TruckPercentInput
                      label=""
                      value={value.truckPct}
                      handleChange={(evt: any) => {
                        handleVolumeChange(key, "truckPct", evt.target.value);
                        handleInvalidInputs(
                          key,
                          3,
                          isNaN(parseInt(evt.target.value))
                        );
                      }}
                      name={`vol-input-${key}`}
                      id={`vol-input-${key}-truckPct`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          ))}
        <Grid item xs={12}>
          <Stack alignItems="center">
            <Button
              onClick={() => setIsCopyPasteDialogOpen(true)}
              sx={{ width: "fit-content" }}
            >
              Copy/Paste volumes
            </Button>
            {pasteError && (
              <Alert onClose={() => setPasteError(undefined)} severity="error">
                {pasteError}
              </Alert>
            )}
            <CopyPasteVolumesDialog
              open={isCopyPasteDialogOpen}
              onClose={() => setIsCopyPasteDialogOpen(false)}
            />
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
