import { useParams } from "react-router-dom";
import { useState } from "react";

import { ApiResponse } from "../api/dataTypes";
import FormComponent from "../Components/reactHookFormComponents/FormComponent";
import { useComments } from "../hooks/useComments";
import { useProjects } from "../hooks/useProjects";
import AddCommentFormFields from "./AddCommentFormFields";
import { CommentType } from "../api/dataTypes";
import { SELECT_ONE_OPTION } from "../Util/FormConstants";

export default function AddCommentForm({
  projectID,
  defaultCommentType,
  size = "medium",
  defaultIntxType,
}: {
  projectID?: string;
  defaultCommentType?: CommentType;
  size?: "small" | "medium";
  defaultIntxType?: string;
}): JSX.Element {
  const [apiResponse, setApiResponse] = useState<ApiResponse | undefined>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  let { id } = useParams();
  if (projectID) id = projectID;

  const { addCommentToProject } = useComments();
  const { fetchProject } = useProjects();

  return (
    <FormComponent
      onFormSubmit={async ({ body, commentType, intersectionType }) => {
        setIsFetching(true);

        const fullbody =
          intersectionType === undefined ||
          intersectionType === SELECT_ONE_OPTION
            ? body
            : intersectionType + ": " + body;
        const response: ApiResponse = await addCommentToProject(
          fullbody,
          Number(commentType),
          id as string
        );
        setIsFetching(false);
        setApiResponse(response);
        if (!response.isError) {
          setTimeout(() => setApiResponse(undefined), 4000);
          fetchProject(id as string);
        }
      }}
    >
      {({ formData, errors, register, resetForm }) => {
        return (
          <AddCommentFormFields
            apiResponse={apiResponse}
            errors={errors}
            isFetching={isFetching}
            register={register}
            resetForm={resetForm}
            defaultField={defaultCommentType}
            size={size}
            displayIntxTypeBool={[
              CommentType.DesignOptions,
              CommentType.Exclusions,
              CommentType.Results,
              CommentType.CostAnalysis,
            ]
              .map(String)
              .includes(formData.commentType ?? `${defaultCommentType}`)}
            defaultIntxType={defaultIntxType}
          />
        );
      }}
    </FormComponent>
  );
}
