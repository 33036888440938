import { intxImages } from "./IntersectionImages";

// import {IntxBuilder} from "../TempEngine/IntxBuilder";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";

// import arrow from "../image/arrow";
import ltArrow from "../images/arrow/LTarrow.svg";
import rtArrow from "../images/arrow/RTarrow.svg";
import rtCArrow from "../images/arrow/RTArrow_channelized.svg";
import thrArrow from "../images/arrow/Thruarrow.svg";
import utArrow from "../images/arrow/UTarrow.svg";
import enArrow from "../images/arrow/Entryarrow.svg";
import ciArrow from "../images/arrow/Circlingarrow.svg";

//import North Arrow diagram from "../image/other";
import northArrowDg from "../images/other/NorthArrow.JPG";
import northArrowRt from "../images/other/NorthArrow_rotate.JPG";
import northArrowSE from "../images/other/NorthArrow_SE.jpg";
import northArrowNE from "../images/other/NorthArrow_NE.jpg";
import cpdLegend from "../images/other/CPDLegend.jpg";
import { convertToTitleCase } from "./UtilFuncs";

export const SignalizedIntersections = [
  {
    // img: conventional,
    title: "Conventional Signal",
    type: IntxBuilder.TYPE_SIGNAL,
    groupLbl: "Signalized",
  },
  {
    // img: bowtie,
    title: "Bowtie",
    description:
      "The bowtie intersection is an alternative to the MUT intersection. Left-turn movements from both roadways are removed from the main intersection and are executed via a U-turn at a roundabout on the minor roadway downstream of the main intersection. Removing the left-turn movements allows for two-phase signal control.",
    designConsideration:
      "The bowtie intersection should be considered on roadways with high through volumes on the major roadway and low through and left-turn volumes on the minor roadway. Arterials with narrow medians and limited ROW should be considered.",
    type: IntxBuilder.TYPE_BOWTIE,
    groupLbl: "Signalized",
  },
  {
    // img: centerTurnOver,
    title: "Center Turn Overpass",
    description:
      "All four left-turn movements are relocated to a grade-separated intersection using narrow ramps within the median. The left-turn traffic then descends back to ground level and merges into through traffic. Both intersections operate under two-phase signal control.",
    designConsideration:
      "The center turn overpass intersection should be considered at high-volume urban or suburban intersections where the major and minor roadways have similar left-turn volumes.",
    type: IntxBuilder.TYPE_CTO,
    groupLbl: "Signalized",
  },
  {
    // img: contGreenT,
    title: "Continuous Green-T",
    description:
      "Through traffic on the top side of a T-intersection is allowed to pass through the intersection without stopping. Left-turn volumes from the minor roadway must merge into the continuous lane of through traffic after completing the left turn. This design allows for three-phase signal control. The tool assumes the intersection is signalized. The intersection may be designed as unsignalized. ",
    designConsideration:
      "The continuous green-T intersection should be considered at three-leg intersections with moderate to low left-turn volumes on the minor roadway, high through volumes on the major roadway, and few pedestrian crossings.",
    type: IntxBuilder.TYPE_CGT,
    groupLbl: "Signalized",
  },
  {
    // img: echelon,
    title: "Echelon",
    description:
      "One approach on both the major and minor roadways is elevated to create two grade-separated intersections of two one-way roads. Each intersection operates under two-phase signal control. ",
    designConsideration:
      "The echelon intersection should be considered at high-volume urban or suburban intersections where the major and minor roadways have similar volumes.",
    type: IntxBuilder.TYPE_ECHELON,
    groupLbl: "Signalized",
  },
  {
    // img: dlt,
    title: "Full Displaced Left Turn",
    description:
      "Left-turning vehicles cross over to the other side of the roadway at a signalized intersection several hundred feet in advance of the main intersection. The protected left turns occur simultaneously with the opposing through movements at the main intersection, allowing for two-phase signal control. This design is also referred to as a Continuous Flow Intersection or Crossover Displaced Left Intersection.",
    designConsideration:
      "The displaced left turn intersection should be considered if opposing approaches have high and balanced through and left-turn volumes.",
    type: IntxBuilder.TYPE_FDLT,
    groupLbl: "Signalized",
  },
  {
    // img: mut,
    title: "Median U-Turn",
    description:
      "Left-turn movements from both roadways are removed from the main intersection. These vehicles instead execute a U-turn at a median opening on the major roadway downstream of the main intersection. Removing the left-turn movements allows for two-phase signal control. This tool assumes all intersections are signalized. However, this intersection can be designed as partially unsignalized.",
    designConsideration:
      "The median U-turn intersection should be considered on high-speed, median-divided highways with moderate left-turn volumes on the major roadway and minor left-turn volumes on the minor roadway.",
    type: IntxBuilder.TYPE_MUT,
    groupLbl: "Signalized",
  },
  {
    // img: dlt,
    title: "Partial Displaced Left Turn",
    description:
      "Left-turning vehicles cross over to the other side of the roadway at a signalized intersection several hundred feet in advance of the main intersection. The protected left turns occur simultaneously with the opposing through movements at the main intersection, allowing for three-phase signal control. This design is also referred to as a Continuous Flow Intersection or Crossover Displaced Left Intersection.",
    designConsideration:
      "The displaced left turn intersection should be considered if opposing approaches have high and balanced through and left-turn volumes.",
    type: IntxBuilder.TYPE_PDLT,
    groupLbl: "Signalized",
  },
  {
    // img: mut,
    title: "Partial Median U-Turn",
    description:
      "Left-turn movements from the major roadway are removed from the main intersection. These vehicles instead execute a U-turn at a median opening on the major roadway downstream of the main intersection. Removing the left-turn movements allows for three-phase signal control. This tool assumes all intersections are signalized. However, this intersection can be designed as partially unsignalized.",
    designConsideration:
      "The median U-turn intersection should be considered on high-speed, median-divided highways with moderate left-turn volumes on the major roadway and minor left-turn volumes on the minor roadway.",
    type: IntxBuilder.TYPE_PMUT,
    groupLbl: "Signalized",
  },
  {
    // img: qr,
    title: "Quadrant Roadway N-E",
    description:
      "All four left-turn movements are rerouted onto a connector road in one quadrant, allowing for two-phase signal control at the main intersection and three-phase signal control at the intersections with the connector road. This tool assumes all intersections are signalized. However, this intersection can be designed as partially unsignalized.",
    designConsideration:
      "The quadrant roadway intersection should be considered if an existing roadway can be used as a connection roadway or there are heavy left-turn and through volumes on the major and minor roadways. The ratio of minor road volume to total intersection volume is typically less than or equal to 0.35.",
    type: IntxBuilder.TYPE_QR_NE,
    groupLbl: "Signalized",
  },
  {
    // img: qr,
    title: "Quadrant Roadway N-W",
    type: IntxBuilder.TYPE_QR_NW,
    groupLbl: "Signalized",
  },
  {
    // img: qr,
    title: "Quadrant Roadway S-E",
    type: IntxBuilder.TYPE_QR_SE,
    groupLbl: "Signalized",
  },
  {
    // img: qr,
    title: "Quadrant Roadway S-W",
    type: IntxBuilder.TYPE_QR_SW,
    groupLbl: "Signalized",
  },
  {
    // img: rcut,
    title: "Restricted Crossing U-Turn",
    description:
      "Minor roadway left-turn and through movements are removed from the main intersection. These vehicles turn right onto the major roadway before making a U-turn at a downstream median opening. Removing these movements allows for two-phase signal control at the main intersection. This design is also referred to as a super street intersection. This tool assumes all intersections are signalized. However, this intersection can be designed as partially or fully unsignalized.",
    designConsideration:
      "The restricted crossing U-turn intersection should be considered on high-speed, median-divided highways with high through and left-turn volumes on the major roadway and low through volumes on the minor roadway.",
    type: IntxBuilder.TYPE_RCUT,
    groupLbl: "Signalized",
  },
  {
    // img: singleLoop,
    title: "Single Loop",
    description:
      "All four left-turn movements and some right-turn movements are rerouted onto a connector road in one quadrant, while the major and minor roadways are grade-separated. This design allows for three-phase signal control at the intersections with the connector road.This tool assumes all intersections are signalized. However, this intersection can be designed as fully unsignalized.",
    designConsideration:
      "The single loop intersection should be considered for roadways with low to medium left-turn volumes.",
    type: IntxBuilder.TYPE_SINGLELOOP,
    groupLbl: "Signalized",
  },
  {
    // img: splitIntx,
    title: "Split Intersection",
    description:
      "The split intersection separates traffic flow on the major roadway into two one-way roads with separate intersections with the minor roadway. Each intersection may operate using three-phase signal control. This configuration is similar to a traditional diamond interchange without grade separation.",
    designConsideration:
      "The split intersection should be considered for isolated, suburban intersections with high left-turn volumes or in urban areas where two-way streets can be converted to one-way streets. The split intersection may also be considered if grade separation may be needed in the future.",
    type: IntxBuilder.TYPE_SPLIT_INTX,
    groupLbl: "Signalized",
  },
  {
    // img: thruCut,
    title: "Thru-Cut",
    description:
      "Through movements from the minor roadway are removed from the main intersection. These vehicles use other routes in the road network to travel across the major street. This design enables protected left turns from both minor street roadways to operate concurrently. Removing minor roadway through movements allows for two- or three-phase signal control, depending on major roadway left-turn phasing.",
    designConsideration:
      "The thru-cut should be considered for roadways with low minor roadway through volumes, sufficient road network to enable minor roadway through movements via alternate routes, and few pedestrian crossings.",
    type: IntxBuilder.TYPE_THRUCUT,
    groupLbl: "Signalized",
  },
];
export const UnsignalizedIntersections = [
  {
    // img: roundabout,
    title: "50 Mini Roundabout",
    description:
      "A roundabout is a circular unsignalized intersection in which traffic flows in one direction around a central island. Traffic entering the roundabout must yield to traffic already inside the roundabout. This tool includes mini roundabouts with a 50 foot or 75 foot inscribed diameter.",
    designConsideration:
      "The mini roundabout should be considered if the posted speed limit on all four approaches is 30 mph or slower and the 85th percentile speed is 35 mph or slower.",
    type: IntxBuilder.TYPE_MINI_RBT_50,
    groupLbl: "Unsignalized",
  },
  {
    // img: roundabout,
    title: "75 Mini Roundabout",
    description:
      "A roundabout is a circular unsignalized intersection in which traffic flows in one direction around a central island. Traffic entering the roundabout must yield to traffic already inside the roundabout. This tool includes mini roundabouts with a 50 foot or 75 foot inscribed diameter.",
    designConsideration:
      "The mini roundabout should be considered if the posted speed limit on all four approaches is 30 mph or slower and the 85th percentile speed is 35 mph or slower.",
    type: IntxBuilder.TYPE_MINI_RBT_75,
    groupLbl: "Unsignalized",
  },
  {
    // img: roundabout,
    title: "Roundabout",
    description:
      "A roundabout is a circular unsignalized intersection in which traffic flows in one direction around a central island. Traffic entering the roundabout must yield to traffic already inside the roundabout. This tool can accommodate roundabouts with up to two lanes in each quadrant.",
    designConsideration:
      "The roundabout should be considered on roadways with a high percentage of turning traffic. Additional information about when roundabouts should be considered may be found on VDOT's website.",
    type: IntxBuilder.TYPE_ROUNDABOUT,
    groupLbl: "Unsignalized",
  },
  {
    // img: dlt,
    title: "Two-Way Stop Control",
    count: 0,
    type: IntxBuilder.TYPE_TWSC,
    groupLbl: "Unsignalized",
  },
];
export const Interchanges = [
  {
    // img: diamond,
    title: "Traditional Diamond",
    description:
      "The traditional diamond interchange is a grade-separated interchange with two intersections on the arterial. The intersections may be signalized or unsignalized. Each direction of travel on the freeway has one on-ramp and one off-ramp.",
    designConsideration:
      "The traditional diamond interchange should be considered in rural and suburban areas with moderate to low ramp volumes.",
    type: IntxBuilder.TYPE_TRAD_DIAMOND,
    groupLbl: "Interchange",
  },
  {
    // img: contraLeft,
    title: "Contraflow Left",
    description:
      "The contraflow left interchange is an alternative to the traditional diamond interchange in which arterial left-turn movements cross over opposing left-turn movements into storage bays prior to the first ramp intersection. These storage bays run in the opposite direction from the adjacent through lanes. This design allows for three-phase signal control.",
    designConsideration:
      "The contraflow left interchange should be considered on arterials with high left-turn volumes onto the freeway ramps, especially if the left-turn demand exceeds the storage capacity between the ramps.",
    type: IntxBuilder.TYPE_CONTRAFLOW_LEFT,
    groupLbl: "Interchange",
  },
  {
    // img: dlt,
    title: "Displaced Left Turn",
    description:
      "The arterial left-turning vehicles cross over to the other side of the roadway at a signalized intersection several hundred feet in advance of the first ramp intersection. At both of the ramp intersections, the protected left turns occur simultaneously with the opposing through movements, allowing for two-phase signal control. This design is also referred to as a Continuous Flow Interchange.",
    designConsideration:
      "The displaced left turn intersection should be considered if there are high and balanced through volumes on the arterial, moderate to high left-turn volumes to the on-ramps, and low to moderate left-turn volumes from the off-ramps.",
    type: IntxBuilder.TYPE_DLTI,
    groupLbl: "Interchange",
  },
  {
    // img: DDI,
    title: "Diverging Diamond",
    description:
      "The diverging diamond interchange is an alternative to the traditional diamond interchange where arterial traffic crosses over to the other side of the roadway in between the two ramps. This allows vehicles to turn left onto the on-ramps without crossing over opposing lanes of traffic. This design is also referred to as the double crossover diamond interchange.",
    designConsideration:
      "The diverging diamond interchange should be considered if there are high left-turn volumes onto and off of the freeway ramps and moderate but unbalanced arterial volumes.",
    type: IntxBuilder.TYPE_DDI,
    groupLbl: "Interchange",
  },
  {
    // img: doubleRoundabout,
    title: "Double Roundabout",
    description:
      "The double roundabout interchange is a grade-separated interchange in which all ramps begin or end at one of two roundabouts on the arterial. This design typically allows for a narrower bridge width than the single roundabout interchange.",
    designConsideration:
      "The double roundabout interchange should be considered if there is a high proportion of left-turns onto the freeway ramps or where there is limited queue storage between the ramp intersections.",
    type: IntxBuilder.TYPE_DOUBLE_RBT,
    groupLbl: "Interchange",
  },
  {
    // img: michUrbanDiamond,
    title: "Michigan Urban Diamond",
    description:
      "The Michigan urban diamond interchange is a variation of the traditional diamond interchange that removes left-turn movements from the arterial intersections. These vehicles instead make U-turns at directional crossovers on frontage roads. The intersections on the arterial operate under two-phase signal control. This design is also referred to as a Median U-Turn (MUT) Interchange. This tool assumes all intersections are signalized. However, this intersection can be designed as partially unsignalized. ",
    designConsideration:
      "The Michigan urban diamond interchange should be considered for arterials with high through volumes and moderate to low left-turn volumes.",
    type: IntxBuilder.TYPE_MUD,
    groupLbl: "Interchange",
  },
  {
    // img: partialClover,
    title: "Partial Cloverleaf",
    description:
      "The partial cloverleaf interchange is a grade-separated interchange with a combination of directional ramps and loop ramps. This configuration may include up to two intersections on the arterial. The intersections may be signalized or unsignalized.",
    designConsideration:
      "The partial cloverleaf interchange should be considered if a traditional diamond interchange would result in moderate to high left-turn volumes onto or off the ramps.",
    type: IntxBuilder.TYPE_PARTIAL_CLOVER,
    groupLbl: "Interchange",
  },
  {
    // img: singlePoint,
    title: "Single Point Urban",
    description:
      "The single point urban interchange is an alternative to the traditional diamond interchange in which all ramps begin or end at a single intersection on the arterial. This configuration allows for three-phase signal control.",
    designConsideration:
      "The single point urban interchange should be considered in urban areas with high left-turn volumes onto and off the freeway ramps or when right-of-way limitations are present.",
    type: IntxBuilder.TYPE_SINGLEPOINT,
    groupLbl: "Interchange",
  },
  {
    // img: singleRoundabout,
    title: "Single Roundabout",
    description:
      "The single roundabout interchange is a grade-separated interchange in which all ramps begin or end at a single roundabout on the arterial.",
    designConsideration:
      "The roundabout interchange should be considered in urban areas with moderate capacity requirements or with right-of-way restrictions.",
    type: IntxBuilder.TYPE_SINGLE_RBT,
    groupLbl: "Interchange",
  },
];

export const FullIntxTypes = [
  ...SignalizedIntersections.map((intx) => intx.type),
  ...UnsignalizedIntersections.map((intx) => intx.type),
  ...Interchanges.map((intx) => intx.type),
];

/**
 * builds an object that looks like:
 * {
 *   [IntxBuilder.TYPE_TRAD_DIAMOND]: {
 *     title: 'Traditional Diamond',
 *     description: ...,
 *     designConsideration: ...,
 *     type: IntxBuilder.TYPE_TRAD_DIAMOND,
 *     groupLbl: "Interchange",
 *   }
 *   ... more intersections
 * }
 */
export const INTX_TYPE_TO_INFO = [
  ...SignalizedIntersections,
  ...UnsignalizedIntersections,
  ...Interchanges,
].reduce((accumulator, intersectionInfo) => {
  return { ...accumulator, [intersectionInfo.type]: intersectionInfo };
}, {});

export const INTX_ORDER = Object.keys(INTX_TYPE_TO_INFO);

export const ArrowMapper = {
  leftArrow: ltArrow,
  thruArrow: thrArrow,
  rightArrow: rtArrow,
  rightArrow_channelized: rtCArrow,
  uturnArrow: utArrow,
  entryArrow: enArrow,
  circArrow: ciArrow,
};

export const OtherImgMapper = {
  northArrowDg,
  northArrowRt,
  northArrowNE,
  northArrowSE,
  cpdLegend,
};

export const IntxIconMapper = {
  // At-Grade Signalized Intersections
  [IntxBuilder.TYPE_SIGNAL]: intxImages.conventionalSignal.icon,
  [IntxBuilder.TYPE_BOWTIE]: intxImages.bowtie.icon,
  [IntxBuilder.TYPE_CTO]: intxImages.centerTurnOverpass.icon,
  [IntxBuilder.TYPE_CGT]: intxImages.continuousGreenT.icon,
  [IntxBuilder.TYPE_ECHELON]: intxImages.echelon.icon,
  [IntxBuilder.TYPE_FDLT]: intxImages.fullDisplacedLeftTurn.icon,
  [IntxBuilder.TYPE_MUT]: intxImages.MUT.icon,
  [IntxBuilder.TYPE_PDLT]: intxImages.partialDLT.icon,
  [IntxBuilder.TYPE_PMUT]: intxImages.partialMUT.icon,
  [IntxBuilder.TYPE_QR_NE]: intxImages.quadrantRoadwayNE.icon,
  [IntxBuilder.TYPE_QR_NW]: intxImages.quadrantRoadwayNW.icon,
  [IntxBuilder.TYPE_QR_SE]: intxImages.quadrantRoadwaySE.icon,
  [IntxBuilder.TYPE_QR_SW]: intxImages.quadrantRoadwaySW.icon,
  [IntxBuilder.TYPE_RCUT]: intxImages.RCUT.icon,
  [IntxBuilder.TYPE_SINGLELOOP]: intxImages.singleLoop.icon,
  [IntxBuilder.TYPE_SPLIT_INTX]: intxImages.split.icon,
  [IntxBuilder.TYPE_THRUCUT]: intxImages.thruCut.icon,
  // At-Grade Unsignalized
  [IntxBuilder.TYPE_MINI_RBT_50]: intxImages.roundaboutMini.icon,
  [IntxBuilder.TYPE_MINI_RBT_75]: intxImages.roundaboutMini.icon,
  [IntxBuilder.TYPE_ROUNDABOUT]: intxImages.roundabout.icon,
  [IntxBuilder.TYPE_TWSC]: intxImages.conventionalSignal.icon,
  // Grade Separated Interchanges
  [IntxBuilder.TYPE_TRAD_DIAMOND]: intxImages.traditionalDiamond.icon,
  [IntxBuilder.TYPE_CONTRAFLOW_LEFT]: intxImages.contraflowLeft.icon,
  [IntxBuilder.TYPE_DLTI]: intxImages.DLTInterchange.icon,
  [IntxBuilder.TYPE_DDI]: intxImages.divergingDiamond.icon,
  [IntxBuilder.TYPE_DOUBLE_RBT]: intxImages.doubleRoundabout.icon,
  [IntxBuilder.TYPE_MUD]: intxImages.michiganUrbanDiamond.icon,
  [IntxBuilder.TYPE_PARTIAL_CLOVER]: intxImages.partialCloverleaf.icon,
  [IntxBuilder.TYPE_SINGLEPOINT]: intxImages.singlePointInterchange.icon,
  [IntxBuilder.TYPE_SINGLE_RBT]: intxImages.singleRdbtInterchange.icon,
};

export const IntxDiagramMapper = {
  // At-Grade Signalized Intersections
  [IntxBuilder.TYPE_SIGNAL]: intxImages.conventionalSignal.diagram,
  [IntxBuilder.TYPE_BOWTIE]: intxImages.bowtie.diagram,
  [IntxBuilder.TYPE_CTO]: intxImages.centerTurnOverpass.diagram,
  [IntxBuilder.TYPE_CGT]: intxImages.continuousGreenT.diagram,
  [IntxBuilder.TYPE_ECHELON]: intxImages.echelon.diagram,
  [IntxBuilder.TYPE_FDLT]: intxImages.fullDisplacedLeftTurn.diagram,
  [IntxBuilder.TYPE_MUT]: intxImages.MUT.diagram,
  [IntxBuilder.TYPE_PDLT]: intxImages.partialDLT.diagram,
  [IntxBuilder.TYPE_PMUT]: intxImages.partialMUT.diagram,
  [IntxBuilder.TYPE_QR_NE]: intxImages.quadrantRoadwayNE.diagram,
  [IntxBuilder.TYPE_QR_NW]: intxImages.quadrantRoadwayNW.diagram,
  [IntxBuilder.TYPE_QR_SE]: intxImages.quadrantRoadwaySE.diagram,
  [IntxBuilder.TYPE_QR_SW]: intxImages.quadrantRoadwaySW.diagram,
  [IntxBuilder.TYPE_RCUT]: intxImages.RCUT.diagram,
  [IntxBuilder.TYPE_SINGLELOOP]: intxImages.singleLoop.diagram,
  [IntxBuilder.TYPE_SPLIT_INTX]: intxImages.split.diagram,
  [IntxBuilder.TYPE_THRUCUT]: intxImages.thruCut.diagram,
  // At-Grade Unsignalized
  [IntxBuilder.TYPE_MINI_RBT_50]: intxImages.roundaboutMini.diagram,
  [IntxBuilder.TYPE_MINI_RBT_75]: intxImages.roundaboutMini.diagram,
  [IntxBuilder.TYPE_ROUNDABOUT]: intxImages.roundabout.diagram,
  [IntxBuilder.TYPE_TWSC]: intxImages.conventionalSignal.diagram,
  // Grade Separated Interchanges
  [IntxBuilder.TYPE_TRAD_DIAMOND]: intxImages.traditionalDiamond.diagram,
  [IntxBuilder.TYPE_CONTRAFLOW_LEFT]: intxImages.contraflowLeft.diagram,
  [IntxBuilder.TYPE_DLTI]: intxImages.DLTInterchange.diagram,
  [IntxBuilder.TYPE_DDI]: intxImages.divergingDiamond.diagram,
  [IntxBuilder.TYPE_DOUBLE_RBT]: intxImages.doubleRoundabout.diagram,
  [IntxBuilder.TYPE_MUD]: intxImages.michiganUrbanDiamond.diagram,
  [IntxBuilder.TYPE_PARTIAL_CLOVER]: intxImages.partialCloverleaf.diagram,
  [IntxBuilder.TYPE_SINGLEPOINT]: intxImages.singlePointInterchange.diagram,
  [IntxBuilder.TYPE_SINGLE_RBT]: intxImages.singleRdbtInterchange.diagram,
};

export const ConflictPointDiagramMapper = {
  // At-Grade Signalized Intersections
  [IntxBuilder.TYPE_SIGNAL]: intxImages.conventionalSignal.conflictDiagram,
  [IntxBuilder.TYPE_SIGNAL + "T"]:
    intxImages.conventionalSignal.conflictDiagramT,
  [IntxBuilder.TYPE_BOWTIE]: intxImages.bowtie.conflictDiagram,
  [IntxBuilder.TYPE_CTO]: intxImages.centerTurnOverpass.conflictDiagram,
  [IntxBuilder.TYPE_CGT]: intxImages.continuousGreenT.conflictDiagram,
  [IntxBuilder.TYPE_ECHELON]: intxImages.echelon.conflictDiagram,
  [IntxBuilder.TYPE_FDLT]: intxImages.fullDisplacedLeftTurn.conflictDiagram,
  [IntxBuilder.TYPE_MUT]: intxImages.MUT.conflictDiagram,
  [IntxBuilder.TYPE_PDLT]: intxImages.partialDLT.conflictDiagram,
  [IntxBuilder.TYPE_PMUT]: intxImages.partialMUT.conflictDiagram,
  [IntxBuilder.TYPE_QR_NE]: intxImages.quadrantRoadwayNE.conflictDiagram,
  [IntxBuilder.TYPE_QR_NW]: intxImages.quadrantRoadwayNW.conflictDiagram,
  [IntxBuilder.TYPE_QR_SE]: intxImages.quadrantRoadwaySE.conflictDiagram,
  [IntxBuilder.TYPE_QR_SW]: intxImages.quadrantRoadwaySW.conflictDiagram,
  [IntxBuilder.TYPE_RCUT]: intxImages.RCUT.conflictDiagram,
  [IntxBuilder.TYPE_SINGLELOOP]: intxImages.singleLoop.conflictDiagram,
  [IntxBuilder.TYPE_SPLIT_INTX]: intxImages.split.conflictDiagram,
  [IntxBuilder.TYPE_THRUCUT]: intxImages.thruCut.conflictDiagram,
  // At-Grade Unsignalized
  [IntxBuilder.TYPE_MINI_RBT_50]: intxImages.roundaboutMini.conflictDiagram,
  [IntxBuilder.TYPE_MINI_RBT_75]: intxImages.roundaboutMini.conflictDiagram,
  [IntxBuilder.TYPE_ROUNDABOUT]: intxImages.roundabout.conflictDiagram,
  [IntxBuilder.TYPE_TWSC]: intxImages.conventionalSignal.conflictDiagram,
  // Grade Separated Interchanges
  [IntxBuilder.TYPE_TRAD_DIAMOND]:
    intxImages.traditionalDiamond.conflictDiagram,
  [IntxBuilder.TYPE_CONTRAFLOW_LEFT]: intxImages.contraflowLeft.conflictDiagram,
  [IntxBuilder.TYPE_DLTI]: intxImages.DLTInterchange.conflictDiagram,
  [IntxBuilder.TYPE_DDI]: intxImages.divergingDiamond.conflictDiagram,
  [IntxBuilder.TYPE_DOUBLE_RBT]: intxImages.doubleRoundabout.conflictDiagram,
  [IntxBuilder.TYPE_MUD]: intxImages.michiganUrbanDiamond.conflictDiagram,
  [IntxBuilder.TYPE_PARTIAL_CLOVER]:
    intxImages.partialCloverleaf.conflictDiagram,
  [IntxBuilder.TYPE_SINGLEPOINT]:
    intxImages.singlePointInterchange.conflictDiagram,
  [IntxBuilder.TYPE_SINGLE_RBT]:
    intxImages.singleRdbtInterchange.conflictDiagram,
};

// constants for matching the id with intersection types for Vjust_config.csv in default values public folder
// Note: should be updated along with the spreadsheet
export const SpreadsheetIdMapper = {
  // At-Grade Signalized Intersections
  40001: IntxBuilder.TYPE_SIGNAL,
  40002: IntxBuilder.TYPE_SIGNAL + " T",
  40003: IntxBuilder.TYPE_BOWTIE,
  40004: IntxBuilder.TYPE_CTO,
  40005: IntxBuilder.TYPE_CGT,
  40006: IntxBuilder.TYPE_ECHELON,
  40007: IntxBuilder.TYPE_FDLT,
  40008: IntxBuilder.TYPE_MUT,
  40009: IntxBuilder.TYPE_PDLT,
  40010: IntxBuilder.TYPE_PMUT,
  40011: IntxBuilder.TYPE_QR_NE,
  40012: IntxBuilder.TYPE_QR_NW,
  40013: IntxBuilder.TYPE_QR_SE,
  40014: IntxBuilder.TYPE_QR_SW,
  40015: IntxBuilder.TYPE_RCUT,
  40016: IntxBuilder.TYPE_SINGLELOOP,
  40017: IntxBuilder.TYPE_SPLIT_INTX,
  40018: IntxBuilder.TYPE_THRUCUT,
  // At-Grade Unsignalized
  40019: IntxBuilder.TYPE_MINI_RBT_50,
  40020: IntxBuilder.TYPE_MINI_RBT_75,
  40021: IntxBuilder.TYPE_ROUNDABOUT,
  40022: IntxBuilder.TYPE_TWSC,
  // Grade Separated Interchanges
  40023: IntxBuilder.TYPE_TRAD_DIAMOND,
  40024: IntxBuilder.TYPE_CONTRAFLOW_LEFT,
  40025: IntxBuilder.TYPE_DLTI,
  40026: IntxBuilder.TYPE_DDI,
  40027: IntxBuilder.TYPE_DOUBLE_RBT,
  40028: IntxBuilder.TYPE_MUD,
  40029: IntxBuilder.TYPE_PARTIAL_CLOVER,
  40030: IntxBuilder.TYPE_SINGLEPOINT,
  40031: IntxBuilder.TYPE_SINGLE_RBT,
};

export const ZonesMapper = {
  [IntxBuilder.TYPE_RCUT]: {
    Z1: { left: 3, top: 31, width: 30, height: 35 },
    Z2: { left: 100 - 30 - 3, top: 31, width: 30, height: 35 },
    Z3: { left: 33, top: 20, width: 32, height: 33 },
    Z4: { left: 35, top: 47, width: 32, height: 33 },
  },
  [IntxBuilder.TYPE_FDLT]: {
    Z1: { left: 38, top: 2, width: 25, height: 30 },
    Z2: { left: 38, top: 67, width: 25, height: 30 },
    Z3: { left: 67, top: 33, width: 25, height: 30 },
    Z4: { left: 10, top: 33, width: 25, height: 30 },
  },
  [IntxBuilder.TYPE_PDLT]: {
    Z1: { left: 18, top: 31, width: 25, height: 35 },
    Z2: { left: 57, top: 36, width: 25, height: 34 },
    Z5: { left: 32, top: 25, width: 35, height: 50 },
  },
  [IntxBuilder.TYPE_THRUCUT]: {
    Z5: { left: 25, top: 20, width: 50, height: 60 },
  },
  [IntxBuilder.TYPE_SIGNAL]: {
    Z5: { left: 25, top: 13, width: 50, height: 70 },
  },
  [IntxBuilder.TYPE_CTO]: {
    Z5Z6: { left: 28, top: 25, width: 42, height: 50 },
  },
  [IntxBuilder.TYPE_CGT]: {
    Z5: { left: 33, top: 17, width: 42, height: 57 },
  },
  [IntxBuilder.TYPE_ECHELON]: {
    Z5Z6: { left: 31, top: 20, width: 42, height: 55 },
  },
  [IntxBuilder.TYPE_TWSC]: {
    Z5: { left: 25, top: 23, width: 50, height: 55 },
  },
  [IntxBuilder.TYPE_SPLIT_INTX]: {
    Z1: { left: 35, top: 8, width: 35, height: 40 },
    Z2: { left: 35, top: 52, width: 35, height: 40 },
  },
  [IntxBuilder.TYPE_MUT]: {
    Z1: { left: 2, top: 31, width: 25, height: 35 },
    Z2: { left: 73, top: 36, width: 25, height: 34 },
    Z5: { left: 36, top: 30, width: 25, height: 40 },
  },
  [IntxBuilder.TYPE_PMUT]: {
    Z1: { left: 2, top: 31, width: 25, height: 35 },
    Z2: { left: 73, top: 36, width: 25, height: 34 },
    Z5: { left: 34, top: 23, width: 33, height: 55 },
  },
  [IntxBuilder.TYPE_BOWTIE]: {
    Z3: { left: 70, top: 36, width: 30, height: 34 },
    Z4: { left: 2, top: 31, width: 30, height: 35 },
    Z5: { left: 36, top: 30, width: 28, height: 40 },
  },
  [IntxBuilder.TYPE_QR_SE]: {
    Z2: { left: 20, top: 48, width: 35, height: 50 },
    Z3: { left: 50, top: 2, width: 35, height: 50 },
    Z5: { left: 20, top: 2, width: 35, height: 50 },
  },
  [IntxBuilder.TYPE_QR_NE]: {
    Z1: { left: 16, top: 2, width: 35, height: 50 },
    Z3: { left: 50, top: 48, width: 30, height: 50 },
    Z5: { left: 18, top: 48, width: 35, height: 50 },
  },
  [IntxBuilder.TYPE_QR_NW]: {
    Z1: { left: 47, top: 2, width: 35, height: 50 },
    Z4: { left: 15, top: 48, width: 35, height: 50 },
    Z5: { left: 50, top: 48, width: 35, height: 50 },
  },
  [IntxBuilder.TYPE_QR_SW]: {
    Z2: { left: 48, top: 48, width: 38, height: 50 },
    Z4: { left: 16, top: 5, width: 35, height: 55 },
    Z5: { left: 48.5, top: 2, width: 35, height: 50 },
  },
  [IntxBuilder.TYPE_SINGLELOOP]: {
    Z2: { left: 45, top: 55, width: 35, height: 44 },
    Z4: { left: 15, top: 8, width: 35, height: 45 },
  },

  // Interchange
  [IntxBuilder.TYPE_TRAD_DIAMOND]: {
    Z3: { left: 30, top: 2, width: 40, height: 45 },
    Z4: { left: 30, top: 50, width: 40, height: 45 },
  },
  [IntxBuilder.TYPE_CONTRAFLOW_LEFT]: {
    Z3: { left: 30, top: 2, width: 40, height: 45 },
    Z4: { left: 30, top: 53, width: 40, height: 45 },
  },
  [IntxBuilder.TYPE_DLTI]: {
    Z1: { left: 30, top: 2, width: 35, height: 35 },
    Z3: { left: 35, top: 28, width: 30, height: 20 },
    Z4: { left: 35, top: 52, width: 30, height: 20 },
    Z6: { left: 37, top: 63, width: 35, height: 35 },
  },
  [IntxBuilder.TYPE_DDI]: {
    Z1: { left: 33, top: 18, width: 35, height: 20 },
    Z2: { left: 33, top: 28, width: 35, height: 20 },
    Z3: { left: 33, top: 15, width: 35, height: 30 },
    Z4: { left: 33, top: 60, width: 35, height: 30 },
    Z5: { left: 33, top: 52, width: 35, height: 20 },
    Z6: { left: 33, top: 65, width: 35, height: 20 },
  },
  [IntxBuilder.TYPE_MUD]: {
    Z1: { left: 30, top: 23, width: 40, height: 35 },
    Z2: { left: 30, top: 42, width: 40, height: 35 },
    Z3: { left: 64, top: 35, width: 35, height: 35 },
    Z4: { left: 2, top: 35, width: 35, height: 35 },
  },
  [IntxBuilder.TYPE_PARTIAL_CLOVER]: {
    Z3: { left: 33, top: 8, width: 35, height: 35 },
    Z4: { left: 33, top: 55, width: 35, height: 35 },
  },
  [IntxBuilder.TYPE_SINGLEPOINT]: {
    Z1: { left: 33, top: 2, width: 35, height: 20 },
    Z3: { left: 25, top: 28, width: 50, height: 50 },
    Z6: { left: 33, top: 73, width: 35, height: 25 },
  },
  [IntxBuilder.TYPE_DOUBLE_RBT]: {
    Terminal1: { left: 29, top: 0, width: 40, height: 60 },
    Terminal2: { left: 29, top: 40, width: 40, height: 59 },
  },
};

//define position for each zone, each direction
export const DisplayPositionMapper = {
  EB: {
    Direction: "EB",
    Arrow: { top: 13, left: 1, rotate: "rotate(0)" },
    Lane: { top: 11, left: 6, rotate: "rotate(0)" },
    Relative: { lt2thru: [0, 5], thru2rt: [0, 10], rt2ut: [0, 15] },
  },
  WB: {
    Direction: "WB",
    Arrow: { top: 23, left: 30, rotate: "rotate(180deg)" },
    Lane: { top: 21, left: 29, rotate: "rotate(0)" },
    Relative: { lt2thru: [0, -5], thru2rt: [0, -10], rt2ut: [0, -15] },
  },
  SB: {
    Direction: "SB",
    Arrow: { top: 2, left: 18.5, rotate: "rotate(90deg)" },
    Lane: { top: 6, left: 20.5, rotate: "rotate(0)" },
    Relative: { lt2thru: [-5, 0], thru2rt: [-10, 0], rt2ut: [-15, 0] },
  },
  NB: {
    Direction: "NB",
    Arrow: { top: 35, left: 16, rotate: "rotate(270deg)" },
    Lane: { top: 29, left: 18, rotate: "rotate(0)" },
    Relative: { lt2thru: [5, 0], thru2rt: [10, 0], rt2ut: [15, 0] },
  },
};

export const RoundaboutPositionMapper = {
  EB: {
    Direction: "EB",
    Arrow: { top: 27, left: 2, rotate: "rotate(90deg)" },
    Lane: { top: 28.5, left: 0, rotate: "rotate(0)" },
    Relative: {
      arr2arr: [" + 4vw", " + 1vh"],
      num2num: [" + 7vw", " + 6vh"],
    },
  },
  WB: {
    Direction: "WB",
    Arrow: { top: 25, left: 33.5, rotate: "rotate(-90deg)" },
    Lane: { top: 26.5, left: 38.5, rotate: "rotate(0)" },
    Relative: {
      arr2arr: [" - 4vw", " + 1vh"],
      num2num: [" - 7vw", " - 5vh"],
    },
  },
  SB: {
    Direction: "SB",
    Arrow: { top: 0, left: 17, rotate: "rotate(180deg)" },
    Lane: { top: -6, left: 18.5, rotate: "rotate(0)" },
    Relative: {
      arr2arr: [" - 0vw", " + 7vh"],
      num2num: [" - 2.5vw", " + 13vh"],
    },
  },
  NB: {
    Direction: "NB",
    Arrow: { top: 53, left: 19, rotate: "rotate(0)" },
    Lane: { top: 62, left: 20, rotate: "rotate(0)" },
    Relative: {
      arr2arr: [" - 0vw", " - 6vh"],
      num2num: [" + 3vw", " - 13vh"],
    },
  },
};

export const BowtiePositionMapper = {
  Z4: {
    Arrow: { top: 26, left: -25, rotate: "rotate(90deg)" },
    Lane: { top: 27.5, left: -27, rotate: "rotate(0)" },
    Relative: {
      arr2arr: [" + 4vw", " + 1vh"],
      num2num: [" + 7vw", " + 6vh"],
    },
  },
  Z3: {
    Arrow: { top: 26, left: 59.5, rotate: "rotate(-90deg)" },
    Lane: { top: 27.5, left: 64.5, rotate: "rotate(0)" },
    Relative: {
      arr2arr: [" - 4vw", " + 1vh"],
      num2num: [" - 7vw", " - 5vh"],
    },
  },
};

/**
 * @param {string} intersectionType one of the IntxBuilder TYPE_s
 * @param {string} leg either "N" "S" "E" or "W"
 * @param {string} intersectionZone for echelon it's either "at_grade_intersection" or "above_grade_intersection"
 * @returns boolean telling if this leg only has lanes that exit the intersection, like at an echelon
 */
export const getIsExitOnlyLeg = ({
  intersectionType,
  leg,
  intersectionZone,
}) => {
  if (intersectionType === IntxBuilder.TYPE_ECHELON) {
    if (intersectionZone === "at_grade_intersection") {
      return leg === "w" || leg === "s";
    }
    if (intersectionZone === "above_grade_intersection") {
      return leg === "n" || leg === "e";
    }
  }
  return false;
};

/**
 * @param {object} inputsClone object that holds form data for step 7 edit dialog form fields
 * @param {string} intersectionType
 * @param {boolean} isNorthLegMajor from the dropdown on step 6
 * @returns array of objects like {label: string, value: string}
 */
const getConvertedSectionLabels = ({
  inputsClone,
  intersectionType,
  isNorthLegMajor,
}) => {
  const sectionKeys = Object.keys(inputsClone).map((key) => ({
    label: key,
    value: key,
  }));

  if (intersectionType === IntxBuilder.TYPE_DLTI) {
    return sectionKeys.map(({ label, value }) => {
      if (label === "left_ramp_terminal")
        return {
          label: `${isNorthLegMajor ? "bottom" : "top"}_ramp_terminal`,
          value,
        };
      if (label === "right_ramp_terminal")
        return {
          label: `${isNorthLegMajor ? "top" : "bottom"}_ramp_terminal`,
          value,
        };
      return { label, value };
    });
  }

  if (
    intersectionType === IntxBuilder.TYPE_CONTRAFLOW_LEFT ||
    intersectionType === IntxBuilder.TYPE_DOUBLE_RBT
  ) {
    return sectionKeys.map(({ label, value }) => {
      if (label === "left_ramp_terminal")
        return {
          label: `${isNorthLegMajor ? "top" : "bottom"}_ramp_terminal`,
          value,
        };
      if (label === "right_ramp_terminal")
        return {
          label: `${isNorthLegMajor ? "bottom" : "top"}_ramp_terminal`,
          value,
        };
      return { label, value };
    });
  }

  if (intersectionType === IntxBuilder.TYPE_DDI) {
    return sectionKeys.map(({ label, value }) => {
      if (label === "bot_ramp_terminal") {
        return {
          label: "bottom_ramp_terminal",
          value,
        };
      }
      return { label, value };
    });
  }

  if (intersectionType === IntxBuilder.TYPE_QR_NE) {
    return sectionKeys.map(({ label, value }) => {
      if (label === "arterialQR_intersection")
        return { label: "east_intersection", value };
      if (label === "crossQR_intersection")
        return { label: "north_intersection", value };
      return { label, value };
    });
  }

  if (intersectionType === IntxBuilder.TYPE_QR_NW) {
    return sectionKeys.map(({ label, value }) => {
      if (label === "arterialQR_intersection")
        return { label: "north_intersection", value };
      if (label === "crossQR_intersection")
        return { label: "west_intersection", value };
      return { label, value };
    });
  }

  if (intersectionType === IntxBuilder.TYPE_QR_SE) {
    return sectionKeys.map(({ label, value }) => {
      if (label === "arterialQR_intersection")
        return { label: "south_intersection", value };
      if (label === "crossQR_intersection")
        return { label: "east_intersection", value };
      return { label, value };
    });
  }

  if (intersectionType === IntxBuilder.TYPE_QR_SW) {
    return sectionKeys.map(({ label, value }) => {
      if (label === "arterialQR_intersection")
        return { label: "west_intersection", value };
      if (label === "crossQR_intersection")
        return { label: "south_intersection", value };
      return { label, value };
    });
  }

  return sectionKeys;
};

/**
 * @param {object} inputsClone object that holds form data for step 7 edit dialog form fields
 * @param {string} intersectionType
 * @param {boolean} isNorthLegMajor from the dropdown on step 6
 * @returns array of objects like {label: string, value: string}
 */
export const getIntxSectionLabels = ({
  inputsClone,
  intersectionType,
  isNorthLegMajor,
}) => {
  return getConvertedSectionLabels({
    inputsClone,
    intersectionType,
    isNorthLegMajor,
  }).map(({ label, value }) => ({
    label: convertToTitleCase(label),
    value,
  }));
};
