import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import {
  ProjectStatus,
  ProjectStatusLabel,
  ProjectStatusMap,
  ProjectStatusLabelMap,
} from "../api/dataTypes";
import { StatusLabel } from "../Util/StatusLabel";

export function getStatusOptions(isUserReviewer: boolean): ProjectStatus[] {
  if (isUserReviewer) {
    return [
      ProjectStatus.NotSubmitted,
      ProjectStatus.Submitted,
      ProjectStatus.UnderReview,
      ProjectStatus.Pending,
      ProjectStatus.Approved,
      ProjectStatus.Archived,
    ];
  }
  return [
    ProjectStatus.NotSubmitted,
    ProjectStatus.Submitted,
    ProjectStatus.Archived,
  ];
}

export function StatusChangeForm({
  projectStatus,
  handleProjectStatusUpdate,
  options,
  isFetching,
}: {
  projectStatus: ProjectStatusLabel;
  handleProjectStatusUpdate: (status: ProjectStatus) => void;
  isFetching: boolean;
  options: ProjectStatus[];
}) {
  return (
    <div>
      <FormControl sx={{ width: 250 }}>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          disabled={isFetching}
          labelId="demo-simple-select-label"
          id="status-update-select"
          value={ProjectStatusMap[projectStatus].toString()}
          label="Status"
          onChange={(e) => handleProjectStatusUpdate(parseInt(e.target.value))}
        >
          {options.map((status) => {
            return (
              <MenuItem
                value={status}
                key={"status-change-option-for" + status}
              >
                {isFetching ? (
                  <Stack alignItems="center">
                    <CircularProgress size={30} />
                  </Stack>
                ) : (
                  <StatusLabel status={ProjectStatusLabelMap[status]} />
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
