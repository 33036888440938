import React from "react";
import Paper from "@mui/material/Paper";
import { DataGrid, GridColDef, GridSortDirection } from "@mui/x-data-grid";
import {
  CommentShape,
  CommentTypeString,
  CommentTypeStringToLabel,
} from "../api/dataTypes";
import RelativeTimeWithTooltip from "../Components/RelativeTimeWithTooltip";
import "./CommentsTable.css";
import { Box, styled } from "@mui/material";

function createData(comment: CommentShape, ownerName: string, index: number) {
  return {
    id: comment.key,
    number: index + 1,
    time: comment.timestamp,
    author: comment.user.name === ownerName ? "You" : comment.user.name,
    type: comment.type,
    comment: comment.body,
  };
}

const columns: GridColDef[] = [
  { field: "number", headerName: "No.", width: 100 },
  {
    field: "time",
    headerName: "Time",
    width: 150,
    renderCell: (params) => <RelativeTimeWithTooltip time={params.value} />,
  },
  { field: "author", headerName: "Author", width: 150 },
  {
    field: "type",
    headerName: "Type",
    width: 200,
    renderCell: (params) =>
      CommentTypeStringToLabel[params.value as CommentTypeString],
  },
  {
    field: "comment",
    headerName: "Comment",
    width: 500,
    sortable: false,
    renderCell: (params) => (
      <div
        style={{
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
          textWrap: "pretty",
        }}
      >
        {params.value}
      </div>
    ),
  },
];

export default function CommentsTable({
  comments,
  owner,
  selectedType,
  pageSize = 10,
}: {
  comments: CommentShape[];
  owner: string;
  selectedType?: CommentTypeString;
  pageSize?: number;
}) {
  let rows;
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize,
    page: 0,
  });

  if (!comments || comments.length === 0) {
    rows = [];
  }

  rows = comments.map((comment, index) => createData(comment, owner, index));

  const pageOptions = pageSize < 10 ? [3, 5, 7] : [10, 20, 50];
  const height =
    Math.max(Math.min(rows.length, paginationModel.pageSize), 3) * 52 + 110;

  return (
    <Paper style={{ minWidth: 650, height }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnSelector={true}
        getRowHeight={() => "auto"}
        getRowClassName={(params) =>
          params.row.type === "Automated" ? "automated-row" : ""
        }
        initialState={{
          filter: selectedType
            ? {
                filterModel: {
                  items: [
                    {
                      field: "type",
                      operator: "equals",
                      value: selectedType,
                    },
                  ],
                },
              }
            : undefined,
          sorting: {
            sortModel: [{ field: "time", sort: "desc" as GridSortDirection }],
          },
        }}
        pageSizeOptions={pageOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          noResultsOverlay: CustomNoResultsOverlay,
        }}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            py: 1,
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15.7px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
        }}
      />
    </Paper>
  );
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .no-rows-primary": {
    fill: "#3D4751",
    ...theme.applyStyles("light", {
      fill: "#AEB8C2",
    }),
  },
  "& .no-rows-secondary": {
    fill: "#1D2126",
    ...theme.applyStyles("light", {
      fill: "#E8EAED",
    }),
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <Box sx={{ mt: 1 }}>No Comments for this project yet.</Box>
    </StyledGridOverlay>
  );
}

function CustomNoResultsOverlay() {
  return (
    <StyledGridOverlay>
      <Box sx={{ mt: 1 }}>No comments found with the current filter.</Box>
    </StyledGridOverlay>
  );
}
