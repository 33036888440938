import { Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import CommentsTable from "../Project/CommentsTable";
import { useCallback } from "react";
import { useAtom } from "jotai";

import { useProjects } from "../hooks/useProjects";
import useUserSession from "../hooks/useUserSession";
import { currentProjectKeyAtom } from "../state/globalState";
import { CommentTypeString, CommentType } from "../api/dataTypes";
import AddCommentForm from "../Project/AddCommentForm";

export default function CommentDialog({
  stepIndex,
  defaultIntxType,
}: {
  stepIndex: number;
  defaultIntxType?: string;
}): JSX.Element {
  const { fetchProject, projectDetails } = useProjects();
  const { isAuthenticated, user } = useUserSession();

  const selectedTypeString = () => {
    switch (stepIndex) {
      case 0:
        return CommentTypeString.ProjectInfo;
      case 1:
        return CommentTypeString.GlobalInputs;
      case 2:
        return CommentTypeString.DesignOptions;
      case 3:
        return CommentTypeString.Exclusions;
      case 4:
        return CommentTypeString.Results;
      case 5:
        return CommentTypeString.CostInput;
      case 6:
        return CommentTypeString.CostAnalysis;
      default:
        return CommentTypeString.Overall;
    }
  };

  const selectedType = () => {
    switch (stepIndex) {
      case 0:
        return CommentType.ProjectInfo;
      case 1:
        return CommentType.GlobalInputs;
      case 2:
        return CommentType.DesignOptions;
      case 3:
        return CommentType.Exclusions;
      case 4:
        return CommentType.Results;
      case 5:
        return CommentType.CostInput;
      case 6:
        return CommentType.CostAnalysis;
      default:
        return CommentType.Overall;
    }
  };

  const [currentProjectKey] = useAtom(currentProjectKeyAtom);

  // Define media queries
  const isSmallScreen = useMediaQuery("(max-height: 900px)");

  // Determine maxHeight based on screen size
  const maxHeight = isSmallScreen ? "300px" : "500px";

  useCallback(() => {
    if (currentProjectKey) {
      fetchProject(currentProjectKey as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectKey]);

  if (!currentProjectKey) return <ErrorResponseComponent />;

  const projectInfo = projectDetails[currentProjectKey as string];

  if (!projectInfo) return <ErrorResponseComponent />;

  const isUserProjectOwner: boolean = projectInfo.user.id === user?.id;
  const isUserReviewer: boolean = projectInfo.projectReviewers.some(
    ({ reviewerId }) => reviewerId === user?.id
  );
  const isUserCommenter: boolean =
    isAuthenticated && (isUserReviewer || isUserProjectOwner);

  return (
    <Box
      sx={{
        position: "fixed",
        right: "20px",
        bottom: "130px",
        maxWidth: "900px",
        maxHeight: maxHeight,
        height: "calc(100% - 200px)",
        backgroundColor: "white",
        padding: "20px",
        boxShadow: 3,
        borderRadius: "20px",
        overflowY: "auto",
        zIndex: 500,
        overflow: "auto",
      }}
    >
      <div style={{ marginBottom: "10px" }}>
        <CommentsTable
          comments={projectInfo.comments}
          owner={projectInfo.user.name}
          selectedType={selectedTypeString()}
          pageSize={3}
        />
      </div>
      {isUserCommenter ? (
        <AddCommentForm
          projectID={currentProjectKey}
          defaultCommentType={selectedType()}
          size="small"
          defaultIntxType={defaultIntxType}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}

function ErrorResponseComponent() {
  return (
    <Box
      sx={{
        position: "fixed",
        right: "20px",
        bottom: "130px",
        maxWidth: "900px",
        maxHeight: "650px",
        backgroundColor: "white",
        padding: "20px",
        boxShadow: 3,
        borderRadius: "20px",
        zIndex: 500,
      }}
    >
      <div>
        Please finish the analysis and submit your project online to enable the
        comment feature.
      </div>
    </Box>
  );
}
